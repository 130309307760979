/* eslint-disable @typescript-eslint/no-explicit-any */
import { Bidder, AdUnit } from '@hbcc/api';
import { parseForm } from 'common/Form/utils';

export const tabs = [
  {
    tab: 'Container Configuration',
    key: 'container-configuration',
  },
  {
    tab: 'Price Bucket Granularity',
    key: 'price-bucket-granularity',
  },
];

export const areValidAdUnits = (adUnits: any[]): boolean => {
  const isValidAdUnit = adUnits.map((adUnit) => {
    if (
      adUnit.code !== '' &&
      adUnit.sizes.length !== 0 &&
      Object.keys(adUnit).length >= 3
    ) {
      return true;
    }
    return false;
  });
  return !isValidAdUnit.includes(false);
};

export const getAdUnitsWithParsedBidders = (
  adUnits: any[],
  allBidders: Bidder[]
): AdUnit[] => {
  const adUnitsAdjusted = adUnits.map((adUnit) => {
    const { code, sizes, ...rest } = adUnit;
    const filteredBidders = Object.fromEntries(
      Object.entries(rest).filter(([, value]) => value !== null)
    );
    const bidders = parseForm(allBidders, filteredBidders);
    const adjustedSizes = sizes.map((size: string) =>
      size.split(',').map(Number)
    );
    return { code, sizes: adjustedSizes, bidders };
  });
  return adUnitsAdjusted;
};

export const getAdUnit = (
  activeTab: string,
  adUnitsValues: any[]
): { adUnit: any; index: number } => {
  const index = Number(activeTab.split('-').slice(-1)[0]);

  return {
    adUnit: adUnitsValues[index],
    index,
  };
};
