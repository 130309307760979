import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CloseButton,
  CloseIcon,
  OverlayContainer,
  StyledTopbar,
  Title,
} from './styles';
import { OverlayModalProps, TopbarProps } from './types';

const Topbar: FunctionComponent<TopbarProps> = ({ children, onClose }) => (
  <StyledTopbar>
    <Title>{children}</Title>
    <CloseButton data-testid="close-overlay" onClick={onClose} type="button">
      <CloseIcon />
    </CloseButton>
  </StyledTopbar>
);

const OverlayModal: FunctionComponent<OverlayModalProps> = ({
  children,
  title,
  visible,
  onClose,
}) => {
  const history = useHistory<{ from: 'string' }>();
  const [visibility, setVisibility] = useState(visible === undefined);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [from, setFrom] = useState(
    history.location.state?.from || '/publishers'
  );

  const closeOverlay = (): void => {
    setVisibility(false);
    history.push(from);
  };

  const handleOverlayClose = (): void => {
    if (onClose === undefined) {
      closeOverlay();
    } else {
      onClose();
    }
  };

  return visibility || visible ? (
    <OverlayContainer>
      <Topbar onClose={handleOverlayClose}>{title}</Topbar>
      {children}
    </OverlayContainer>
  ) : null;
};

export default OverlayModal;
