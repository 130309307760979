import styled, { css } from 'styled-components';
import { AlertCircle } from '@easy-eva-icons/react';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 16px;
`;

export const sharedStyles = css`
  box-sizing: border-box;
  color: #616d7c;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0;
  background-color: #fff;
  border: 2px solid #cad2d9;
  border-radius: 4px;
  outline: none;
  caret-color: #0967d2;

  &:active,
  &:focus {
    background-color: #fff;
    border-color: #47a3f3;
    box-shadow: 0 2px 8px -2px rgba(71, 163, 243, 0.5);
  }

  &:disabled {
    color: #9aa4b0;
    background-color: #f5f7f9;
    border-color: #eff1f3;
    box-shadow: none;
    cursor: not-allowed;
  }

  &::placeholder {
    color: #9aa4b0;
  }
`;

export const InputField = styled.input<{
  errors?: boolean;
  addonBefore: boolean;
  addonAfter: boolean;
}>`
  ${sharedStyles};
  width: 100%;
  height: 40px;
  padding: 0 16px;
  transition: all 0.2s ease-in-out;

  &:hover:not(:disabled) {
    ${({ errors }) =>
      !errors &&
      css`
        border-color: #47a3f3;
      `}
  }

  ${({ errors }) =>
    errors &&
    css`
      border-color: #f86a6a;
      background-color: #fff1f0;
    `}

  ${({ addonBefore }) =>
    addonBefore &&
    css`
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    `}

  ${({ addonAfter }) =>
    addonAfter &&
    css`
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    `}
`;

export const Label = styled.label`
  margin-bottom: 4px;
  color: #9aa4b0;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1.13px;
`;

export const ErrorsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2px;
`;

export const ErrorIcon = styled(AlertCircle)`
  align-self: start;
  min-width: 16px;
  min-height: 16px;
  margin-right: 4px;
  color: #e02d38;
`;

export const Errors = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Error = styled.div`
  color: #e02d38;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
`;

export const InputElement = styled.span`
  display: flex;
`;

export const AddonBefore = styled.span<{
  errors?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 11px;
  color: #9aa4b0;
  text-align: center;
  background-color: #f5f7f9;
  border-top: 2px solid #cad2d9;
  border-bottom: 2px solid #cad2d9;
  border-left: 2px solid #cad2d9;
  border-radius: 4px 0 0 4px;
  ${({ errors }) =>
    errors &&
    css`
      border-color: #f86a6a;
      background-color: #fff1f0;
    `}
`;

export const AddonAfter = styled(AddonBefore)<{
  errors?: boolean;
}>`
  border-right: ${({ errors }) =>
    errors ? '2px solid #f86a6a' : '2px solid #cad2d9'};
  border-left: none;
  border-radius: 0 4px 4px 0;
`;
