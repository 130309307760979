import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {
  SignIn,
  PasswordSetup,
  PasswordReset,
} from 'services/Auth/CognitoAuth';
import { UserContext, authUser } from 'contexts/UserContext';
import message from 'components/Message';
import { routes } from 'settings';
import { SignInForm, PasswordSetupForm, PasswordResetForm } from './Forms';

type AuthState = '' | 'NEW_PASSWORD_REQUIRED' | 'RESET_REQUIRED';

const SignInPage: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [user, setUser] = useState<any>({});
  const [currentName, setCurrentName] = useState<string>('');
  const [authState, setAuthState] = useState<AuthState>('');
  const history = useHistory();
  const location = useLocation();
  const {
    state: { isAuth },
    dispatch,
  } = useContext(UserContext);

  const handleRedirect = (): void => {
    const { next } = queryString.parse(location.search);
    if (next) {
      history.push(next as string);
      return;
    }

    history.push(routes.publishers);
  };

  useEffect(() => {
    if (isAuth) {
      handleRedirect();
    }
  }, [isAuth, history]); // eslint-disable-line react-hooks/exhaustive-deps

  const onPasswordSetup = (newPassword: string) => {
    PasswordSetup({
      user,
      newPassword,
      onSuccess: () => {
        dispatch(authUser());
        setAuthState('');
        history.push(routes.publishers);
        message.success('Password changed successfully.');
      },
      onError: () => {
        message.error('Provided data was incorrect.');
      },
    });
  };

  const onPasswordReset = (
    username: string,
    code: string,
    newPassword: string
  ) => {
    PasswordReset({
      username,
      newPassword,
      code,
      onSuccess: () => {
        setAuthState('');
        message.success('Password reset successfully.');
      },
      onError: () => {
        message.error('Provided data was incorrect.');
      },
    });
  };

  const onSignIn = (username: string, password: string) => {
    SignIn({
      username,
      password,
      onPasswordChallenge: (newUser) => {
        setUser(newUser);
        setAuthState('NEW_PASSWORD_REQUIRED');
      },
      onPasswordReset: () => {
        setCurrentName(username);
        setAuthState('RESET_REQUIRED');
      },
      onSuccess: () => {
        dispatch(authUser());
        history.push(routes.publishers);
      },
      onInvalidData: () => {
        message.error('Provided data was incorrect.');
      },
      onUnknownError: () => {
        message.error('Something went wrong.');
      },
    });
  };

  switch (authState) {
    case 'NEW_PASSWORD_REQUIRED':
      return <PasswordSetupForm onPasswordSetup={onPasswordSetup} />;
    case 'RESET_REQUIRED':
      return (
        <PasswordResetForm
          onPasswordReset={onPasswordReset}
          currentName={currentName}
        />
      );
    default:
      return <SignInForm onSignIn={onSignIn} />;
  }
};

export default SignInPage;
