import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Header, Container } from 'common';
import { H1 } from 'common/Typography';
import Table from 'components/Table';
import { ContainerListItem } from '@hbcc/api';
import Button from 'components/Button';
import { generatePath, useHistory } from 'react-router-dom';
import { routes } from 'settings';
import containerService from 'services/Container/container.service';
import { PublisherContext } from 'contexts/PublisherContext/PublisherContext';
import message from 'components/Message';
import StatusTag from './status/StatusTag';
import ActionDropdown from './ActionDropdown';

const Publishers: React.FC = () => {
  const history = useHistory();
  const { state } = useContext(PublisherContext);
  const { currentPublisher } = state;
  const [containers, setContainers] = useState<ContainerListItem[]>([]);

  const fetchContainers = () => {
    if (currentPublisher.id > 0) {
      containerService
        .getAll(currentPublisher.id)
        .then(({ data }) => {
          setContainers(data);
        })
        .catch(() =>
          message.error('Error occured while fetching containers list.')
        );
    }
  };

  useEffect(() => {
    fetchContainers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPublisher.id]);

  const columns = useMemo(
    () => [
      {
        Header: "Container's name",
        accessor: 'name',
        Cell({ value }: { value: string }) {
          return <strong>{value}</strong>;
        },
      },
      {
        Header: 'Bidders',
        accessor: 'configuredBidders',
        Cell({ value }: { value: number }) {
          return value;
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell({ row: { original } }: { row: { original: ContainerListItem } }) {
          return <StatusTag container={original}>{original.status}</StatusTag>;
        },
      },
      {
        Header: () => null,
        accessor: 'actions',
        Cell({ row: { original } }: { row: { original: ContainerListItem } }) {
          return (
            <ActionDropdown container={original} fetchData={fetchContainers} />
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Container>
      <Header>
        <H1>Containers list</H1>
        <Button
          buttonType="primary"
          onClick={() =>
            history.push(generatePath(routes.containerForm), {
              from: history.location.pathname,
            })
          }
        >
          Add Container
        </Button>
      </Header>
      <Table<ContainerListItem>
        columns={columns}
        data={containers}
        loading={false}
        pagination
      />
    </Container>
  );
};

export default Publishers;
