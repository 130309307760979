import { Auth } from 'aws-amplify';
import { setUserData, userDispatch as dispatch } from 'contexts/UserContext';

interface ISignIn {
  username: string;
  password: string;
  onPasswordChallenge: (user: Record<string, unknown>) => void;
  onPasswordReset: (username: string) => void;
  onSuccess: (user: Record<string, unknown>) => void;
  onInvalidData?: (error: Record<string, unknown>) => void;
  onUnknownError: (error: Record<string, unknown>) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SignIn = async (props: ISignIn): Promise<void> => {
  const {
    username,
    password,
    onPasswordChallenge,
    onPasswordReset,
    onSuccess,
    onInvalidData,
    onUnknownError,
  } = props;
  try {
    const user = await Auth.signIn(username, password);
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      onPasswordChallenge(user);
    } else {
      onSuccess(user);
      dispatch(setUserData({ email: username }));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.code === 'PasswordResetRequiredException') {
      onPasswordReset(username);
    } else if (
      error.code === 'NotAuthorizedException' ||
      error.code === 'UserNotFoundException'
    ) {
      if (onInvalidData) onInvalidData(error);
    } else {
      onUnknownError(error);
    }
  }
};

interface IPasswordSetup {
  user: { challengeParam: { userAttributes: { email: string } } };
  newPassword: string;
  onSuccess: (user: Record<string, unknown>) => void;
  onError: (error: Record<string, unknown>) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PasswordSetup = async (props: IPasswordSetup): Promise<void> => {
  const { user, newPassword, onSuccess, onError } = props;
  try {
    const loggedUser = await Auth.completeNewPassword(user, newPassword, {
      email: user.challengeParam.userAttributes.email,
    });
    onSuccess(loggedUser);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    onError(error);
  }
};

interface IPasswordReset {
  username: string;
  newPassword: string;
  code: string;
  onSuccess: () => void;
  onError: (error: Record<string, unknown>) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PasswordReset = async (props: IPasswordReset): Promise<void> => {
  const { username, newPassword, code, onSuccess, onError } = props;
  try {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
    onSuccess();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    onError(error);
  }
};

interface SessionTokens {
  jwtToken: string;
  refreshToken: string;
}

const fetchTokens = async (): Promise<SessionTokens> => {
  const session = await Auth.currentSession();

  return {
    jwtToken: session.getAccessToken().getJwtToken(),
    refreshToken: session.getRefreshToken().getToken(),
  };
};

export { SignIn, PasswordSetup, fetchTokens, PasswordReset };
