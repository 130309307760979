import React, { useState, useEffect } from 'react';
import { ICheckbox } from './types';
import { Label, Checkmark, Icon, Input } from './styles';

const Checkbox: React.FC<ICheckbox> = ({
  id,
  children,
  checked = false,
  defaultChecked = false,
  // disabled = false,
  onChange,
  inputProps,
  ...rest
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(
    checked || defaultChecked
  );

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(e);

    setIsChecked(!isChecked);
  };

  return (
    <Label {...rest}>
      <Checkmark checked={isChecked}>
        <Icon viewBox="0 0 24 24" className="checkmark">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </Checkmark>
      {children}
      <Input
        id={id}
        type="checkbox"
        checked={isChecked}
        onChange={handleOnChange}
        {...inputProps}
      />
    </Label>
  );
};

export default Checkbox;
