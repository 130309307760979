import React from 'react';
import { DividerLine } from './styles';
import { IDivider } from './types';

const Divider: React.FC<IDivider> = ({
  type = 'horizontal',
  compact = false,
  ...rest
}) => <DividerLine type={type} compact={compact} {...rest} />;

export default Divider;
