/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React, { useContext } from 'react';
import { MoreHorizontalOutline } from '@easy-eva-icons/react';

import Button from 'components/Button';
import Dropdown, { Item } from 'components/Dropdown';
import { confirm } from 'components/Modal';
import message from 'components/Message';
import containerService from 'services/Container/container.service';
import { PublisherContext } from 'contexts/PublisherContext/PublisherContext';
import { generatePath, useHistory } from 'react-router-dom';
import { routes } from 'settings';
import { ContainerListItem } from '@hbcc/api';
import {
  EditIcon,
  TrashIcon,
  Container,
  SetupInstructionsIcon,
} from './styles';

export function deleteContainer(
  publisherId: number,
  containerId: string,
  fetchData: () => void
): void {
  containerService
    .deleteContainer(publisherId, containerId)
    .then(() => {
      message.success('Container was successfully deleted.');
      fetchData();
    })
    .catch(() => message.error('Something went wrong.'));
}

const ActionDropdown: React.FC<{
  container: ContainerListItem;
  fetchData: () => void;
}> = ({ container, fetchData }) => {
  const history = useHistory();
  const { state } = useContext(PublisherContext);
  const { currentPublisher } = state;

  const deleteModal = () =>
    confirm({
      onOk: () =>
        deleteContainer(currentPublisher.id, container.containerId, fetchData),
      title: `Delete ${container.name}`,
      description: (
        <>
          Are you sure you want to delete <strong>{container.name}</strong>?
        </>
      ),
    });

  const getContainerAndRedirectToSetup = () => {
    containerService
      .getContainer(currentPublisher.id, container.containerId)
      .then(({ data }) => {
        const { bidConfig, adUnits } = data;
        history.push(
          generatePath(routes.setupInstructions, {
            containerId: container.containerId,
          }),
          {
            setupData: {
              publisherId: currentPublisher.id,
              containerId: container.containerId,
              adUnits,
              libraries: bidConfig.libraries,
            },
          }
        );
      })
      .catch(() => message.error('Error occured while fetching container.'));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onClick = (key: any) => {
    switch (key) {
      case 'edit':
        history.push(
          generatePath(routes.containerForm, {
            containerId: container.containerId,
          })
        );
        break;
      case 'delete':
        deleteModal();
        break;
      case 'setup-instructions':
        getContainerAndRedirectToSetup();
        break;
      default:
        break;
    }
  };

  const items = (
    <div onClick={(key) => onClick(key)}>
      <Item key="edit" data-testid="edit">
        <EditIcon />
        Edit
      </Item>
      <Item key="delete" data-testid="delete">
        <TrashIcon />
        Delete
      </Item>
      <Item key="setup-instructions" data-testid="setup-instructions">
        <SetupInstructionsIcon />
        Setup instructions
      </Item>
    </div>
  );

  return (
    <Container>
      <Dropdown items={items} position="right">
        <Button buttonType="transparent" shape="square">
          <MoreHorizontalOutline />
        </Button>
      </Dropdown>
    </Container>
  );
};

export default ActionDropdown;
