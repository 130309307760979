import React, { useEffect, useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Amplify, { Auth } from 'aws-amplify';

import { routes, awsConfig } from 'settings';
import Layout from 'common/Layout';
import LoggedIn from 'common/LoggedIn';
import SignIn from 'views/SignIn';
import Publishers from 'views/Publishers';
import Publisher from 'views/PublisherForm';
import { UserContext, authUser, setUserData } from 'contexts/UserContext';
import Containers from 'views/Containers';
import Container from 'views/ContainerForm/Container';
import { PublisherContextProvider } from 'contexts/PublisherContext/PublisherContext';
import SetupInstractions from 'views/SetupInstuctions/SetupInstractions';

const App: React.FC = () => {
  const userContext = useContext(UserContext);
  useEffect(() => {
    // TODO: move this to the CognitoAuth.ts service
    Amplify.configure(awsConfig);
    Auth.currentAuthenticatedUser()
      .then((user) => {
        userContext.dispatch(authUser());
        userContext.dispatch(setUserData({ email: user.attributes.email }));
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // temporary tokens log
  useEffect(() => {
    if (userContext.state.isAuth) {
      Auth.currentSession()
        // eslint-disable-next-line no-console
        .then((data) => console.log(data))
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err));
    }
  }, [userContext]);

  return (
    <Switch>
      <Route exact path={routes.signIn} component={SignIn} />
      <LoggedIn>
        <PublisherContextProvider>
          <Layout>
            <Switch>
              <Route exact path={routes.publishers} component={Publishers} />
              <Route exact path={routes.publisherForm} component={Publisher} />
              <Route exact path={routes.containers} component={Containers} />
              <Route exact path={routes.containerForm} component={Container} />
              <Route
                exact
                path={routes.setupInstructions}
                component={SetupInstractions}
              />
              <Route exact path={routes.containerForm} component={Container} />
              <Route
                render={(): JSX.Element => <Redirect to={routes.publishers} />}
              />
            </Switch>
          </Layout>
        </PublisherContextProvider>
      </LoggedIn>
    </Switch>
  );
};

export default App;
