import Button from 'components/Button';

import Avatar from 'components/Avatar';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 80vh;
`;

export const Head = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CompanyAvatar = styled(Avatar)`
  margin-bottom: 16px;
`;

export const Header = styled.div`
  margin-bottom: 24px;
  color: #10110f;
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0;
`;

export const Form = styled.form`
  width: 340px;
`;

export const SubmitButton = styled(Button)`
  width: 340px;
`;
