import React, { forwardRef } from 'react';
import {
  InputContainer,
  Label,
  ErrorsContainer,
  ErrorIcon,
  Errors,
} from 'components/Input/styles';
import { renderErrors } from 'components/Input/utils';
import { TextAreaField } from './styles';
import { ITextAreaField } from './types';

const TextArea = forwardRef<HTMLTextAreaElement, ITextAreaField>(
  function TextArea({ label, id, errors, ...rest }, ref) {
    return (
      <InputContainer>
        {label && <Label htmlFor={id}>{label}</Label>}
        <TextAreaField
          spellCheck="false"
          ref={ref}
          id={id}
          errors={!!errors?.length}
          {...rest}
        />
        {errors?.length && (
          <ErrorsContainer>
            <ErrorIcon />
            <Errors>{renderErrors(errors)}</Errors>
          </ErrorsContainer>
        )}
      </InputContainer>
    );
  }
);

export default TextArea;
