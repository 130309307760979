import React, { useState, useRef } from 'react';
import { TooltipWrapper, TooltipTip } from './styles';
import { ITooltip } from './types';

const Tooltip: React.FC<ITooltip> = ({
  children,
  content,
  position = 'top',
  ...rest
}) => {
  const [active, setActive] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <TooltipWrapper
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      {...rest}
    >
      <div ref={ref}>{children}</div>
      {active && (
        <TooltipTip childHeight={ref.current?.offsetHeight} position={position}>
          {content}
        </TooltipTip>
      )}
    </TooltipWrapper>
  );
};

export default Tooltip;
