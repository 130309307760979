import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { ReactComponent as Emblem } from 'assets/emblem.svg';
import Input from 'components/Input';
import {
  Container,
  Head,
  CompanyAvatar,
  Header,
  Form,
  SubmitButton,
} from './styles';

const passwordRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*\-(){}[\]:;<>,./~_+=|\\]).{8,}$/;

type PasswordResetInputs = {
  code: string;
  newPassword: string;
  repeatPassword: string;
};

interface IPasswordResetForm {
  onPasswordReset: (username: string, code: string, password: string) => void;
  currentName: string;
}

const PasswordResetForm: React.FC<IPasswordResetForm> = ({
  onPasswordReset,
  currentName,
}) => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isDirty, isValid },
  } = useForm<PasswordResetInputs>({ mode: 'onChange' });

  const onSubmit: SubmitHandler<PasswordResetInputs> = async ({
    code,
    newPassword,
  }) => {
    onPasswordReset(currentName, code, newPassword);
  };

  return (
    <Container>
      <Head>
        <CompanyAvatar svg={<Emblem />} />
        <Header>Change password</Header>
      </Head>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input data-testid="username" disabled defaultValue={currentName} />
        <Input
          data-testid="verificationCode"
          placeholder="Type verification code"
          {...register('code', {
            required: 'This field is required!',
          })}
          errors={errors.code?.message}
        />
        <Input
          data-testid="newPassword"
          placeholder="Type new password"
          type="password"
          autoComplete="new-password"
          {...register('newPassword', {
            required: 'This field is required!',
            pattern: {
              value: passwordRegex,
              message:
                'Password should be min 8 characters long and contain 1 upper case letter, 1 number, 1 special character.',
            },
          })}
          errors={errors.newPassword?.message}
        />
        <Input
          data-testid="repeatPassword"
          placeholder="Repeat new password"
          type="password"
          autoComplete="new-password"
          {...register('repeatPassword', {
            required: 'This field is required!',
            validate: (value) =>
              value === getValues('newPassword') || 'Password mismatch!',
          })}
          errors={errors.repeatPassword?.message}
        />
        <SubmitButton
          data-testid="resetPassword"
          type="submit"
          disabled={!isDirty || !isValid}
        >
          Reset password
        </SubmitButton>
      </Form>
    </Container>
  );
};

export default PasswordResetForm;
