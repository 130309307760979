import React from 'react';
import Navbar from 'common/Navbar';
import Sidebar, { SidebarWidth } from 'common/Sidebar';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-left: ${SidebarWidth};
  padding: 35px 72px 35px 60px;
`;

const Layout: React.FC = ({ children }) => (
  <>
    <Navbar />
    <Container>
      <Sidebar />
      <Content>{children}</Content>
    </Container>
  </>
);

export default Layout;
