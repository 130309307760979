import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Scripts = styled.pre`
  width: min-content;
  padding: 12px;
  color: rgb(0, 25, 58);
  font-size: 14px;
  font-family: 'Fira Code', monospace;
  line-height: 1.42857;
  background-color: #eaeef3;
  border-radius: 3px;
`;
