/* eslint-disable jsx-a11y/no-autofocus */
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { Item } from 'components/Dropdown/styles';
import Tag from 'components/Tag';
import Empty from 'components/Empty';
import { renderErrors } from 'components/Input/utils';
import { ErrorIcon, Errors, ErrorsContainer } from 'components/Input/styles';
import {
  Clear,
  Chevron,
  Search,
  Details,
  Label,
  Input,
  SelectContainer,
  SelectContent,
  SelectWrapper,
  SelectCheckbox,
  OptionsContainer,
  ItemsList,
  Spinner,
} from './styles';
import { ISelect } from './types';

const Select: React.FC<ISelect> = ({
  id,
  label,
  errors,
  disabled = false,
  placeholder,
  value,
  options,
  mode = 'single',
  allowClear = false,
  showSearch = false,
  showSelectedTags = true,
  disableOutsideClick = false,
  actionHandlers = false,
  outsideCancel = false,
  loading = false,
  onChange,
  onSelect,
  dropdownRender,
  ...rest
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  // handle defaultValue when using form
  const [selected, setSelected] = useState<string[]>([]);
  const [selectedCopy, setSelectedCopy] = useState<string[]>([]);

  useEffect(() => {
    if (value) {
      setSelected(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const returnSelectedValue = useCallback(() => {
    const array = selected.slice();
    return mode === 'multiple' ? array : array[0];
  }, [selected, mode]);

  const closeSelect = useCallback(
    (revert = false, bubbleAction = true) => {
      setIsOpen(false);

      if (showSearch) {
        setSearch('');
      }

      if (revert) {
        setSelected(selectedCopy);
      }

      if (bubbleAction && actionHandlers && onChange && !outsideCancel) {
        onChange(returnSelectedValue());
      }
    },
    [
      actionHandlers,
      onChange,
      outsideCancel,
      returnSelectedValue,
      selectedCopy,
      showSearch,
    ]
  );

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (
        (!disableOutsideClick || outsideCancel) &&
        isOpen &&
        ref.current &&
        !ref.current.contains(e.target as Node)
      ) {
        closeSelect(outsideCancel);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, showSearch, disableOutsideClick, outsideCancel, closeSelect]);

  const onMouseEnter = () => {
    if (!disabled) {
      setIsHover(true);
    }
  };

  const onMouseLeave = () => {
    if (!disabled) {
      setIsHover(false);
    }
  };

  const onSelectClick = () => {
    if (!disabled && !loading) {
      setSelectedCopy(selected.slice());

      if (mode === 'single') {
        if (showSearch && !isOpen) {
          setIsOpen(true);
        } else if (!showSearch) {
          setIsOpen(!isOpen);
        }
      }

      if (mode === 'multiple') {
        if (showSearch && !isOpen) {
          setIsOpen(true);
        } else if (!showSearch) {
          setIsOpen(!isOpen);
        }
      }
    }
  };

  const onOptionSelect = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    e:
      | React.MouseEvent<HTMLLIElement, MouseEvent>
      | React.ChangeEvent<HTMLInputElement>,
    item: string
  ) => {
    if (onSelect) {
      onSelect(item);
    }

    if (mode === 'single') {
      setSelected([item]);

      if (!actionHandlers) {
        if (!disableOutsideClick) {
          setIsOpen(false);
        }

        if (onChange) {
          onChange(item);
        }

        if (showSearch) {
          setSearch('');
        }
      }
    }

    if (mode === 'multiple') {
      let copy = selected.slice();
      const idx = copy.indexOf(item);
      if (idx === -1) {
        copy = [...copy, item];
      } else {
        copy = copy.filter((pick) => pick !== item);
      }

      setSelected(copy);

      if (onChange && !actionHandlers) {
        onChange(copy);
      }
    }
  };

  const onClearAll = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
    e.stopPropagation();
    if (onChange) {
      if (mode === 'single') {
        onChange(null);
      } else {
        onChange([]);
      }
    }

    setSelected([]);
  };

  const onTagClose = useCallback(
    (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      e: React.MouseEvent<HTMLElement, MouseEvent>,
      item: string
    ) => {
      if (mode === 'multiple') {
        setSelected((prevState) => {
          const newSelected = prevState.filter((pick) => pick !== item);
          if (onChange) {
            onChange(newSelected);
          }
          return newSelected;
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mode]
  );

  const onCancel = () => {
    closeSelect(true, false);
  };

  const onSave = () => {
    closeSelect(false, false);

    if (actionHandlers && onChange) {
      onChange(returnSelectedValue());
    }

    return selected;
  };

  const renderIcon = () => {
    if (allowClear && selected && selected.length && isHover) {
      return (
        <Clear data-testid="select-clear-all" onClick={(e) => onClearAll(e)} />
      );
    }

    if (isOpen && showSearch) {
      return <Search />;
    }

    if (loading) {
      return <Spinner />;
    }

    return <Chevron />;
  };

  const renderDetailsText = () => {
    if (mode === 'multiple') {
      return selected && selected.length
        ? `${selected.length} items selected`
        : placeholder || 'Select items';
    }

    return (
      (options &&
        options.find((option) => option.value === selected[0])?.label) ||
      placeholder ||
      'Select item'
    );
  };

  const renderDetails = useMemo(() => {
    if (showSearch && isOpen) {
      return (
        <Input
          autoFocus
          disabled={disabled}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={renderDetailsText()}
          data-testid="select-search"
        />
      );
    }

    return <Details>{renderDetailsText()}</Details>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSearch, isOpen, selected]);

  const renderOptions = useMemo(() => {
    if (!options) return [];

    let returnOptions = [];
    if (showSearch && search.length) {
      returnOptions = options
        .filter((option) => option.label.indexOf(search) > -1)
        .map((result) => (
          <Item
            key={result.value}
            onClick={(e) => onOptionSelect(e, result.value)}
            active={selected.indexOf(result.value) > -1}
            data-testid="select-options-item"
          >
            {mode === 'multiple' ? (
              <SelectCheckbox
                id={result.value}
                checked={selected.indexOf(result.value) > -1}
              >
                {result.label}
              </SelectCheckbox>
            ) : (
              result.label
            )}
          </Item>
        ));
    } else {
      returnOptions = options.map((option) => (
        <Item
          key={option.value}
          onClick={(e) => onOptionSelect(e, option.value)}
          active={selected.indexOf(option.value) > -1}
          data-testid="select-options-item"
        >
          {mode === 'multiple' ? (
            <SelectCheckbox
              id={option.value}
              checked={selected.indexOf(option.value) > -1}
              onChange={(e) => e.stopPropagation()}
            >
              {option.label}
            </SelectCheckbox>
          ) : (
            option.label
          )}
        </Item>
      ));
    }

    return returnOptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, showSearch, mode, search, selected]);

  const renderDropdown = useMemo(
    () => {
      if (!renderOptions.length) {
        return <Empty padded />;
      }

      if (dropdownRender && isOpen) {
        return dropdownRender(
          <ItemsList>{renderOptions}</ItemsList>,
          onCancel,
          onSave
        );
      }

      return <ItemsList>{renderOptions}</ItemsList>;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dropdownRender, renderOptions, isOpen]
  );

  const renderSelectedItems = useMemo(() => {
    if (mode === 'multiple' && selected.length) {
      return selected.map((item) => (
        <Tag
          data-testid="select-tag-item"
          key={item}
          closable
          onClose={(e) => onTagClose(e, item)}
        >
          {options.find((option) => option.value === item)?.label}
        </Tag>
      ));
    }

    return null;
  }, [mode, selected, onTagClose, options]);

  return (
    <SelectContainer data-testid={`select-container-${id}`} {...rest}>
      {label && <Label>{label}</Label>}
      <SelectContent ref={ref} data-testid={`select-content-${id}`}>
        <SelectWrapper
          data-testid={`select-${id}`}
          open={isOpen}
          errors={!!errors?.length}
          showSearch={showSearch}
          disabled={disabled}
          onClick={onSelectClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {renderDetails}
          {renderIcon()}
        </SelectWrapper>
        {isOpen && (
          <OptionsContainer position="bottom">
            {renderDropdown}
          </OptionsContainer>
        )}
      </SelectContent>
      {showSelectedTags && renderSelectedItems}
      {errors?.length && (
        <ErrorsContainer>
          <ErrorIcon />
          <Errors>{renderErrors(errors)}</Errors>
        </ErrorsContainer>
      )}
    </SelectContainer>
  );
};

export default Select;
