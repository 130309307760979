import React, { useState, useRef, useEffect, useMemo } from 'react';
import { IDropdown } from './types';
import { ItemsContainer, ItemsList, DropdownContainer, Item } from './styles';

const Dropdown: React.FC<IDropdown> = ({
  children,
  items,
  position = 'right',
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const handleClick = (e: Event) => {
    e.preventDefault();
    setOpen(!open);
  };

  const renderItems = useMemo(
    () =>
      React.Children.map(items, (item) =>
        React.Children.map(item.props.children, (child) =>
          React.cloneElement(child, {
            onClick: () => {
              item.props.onClick(child.key);
              setOpen(false);
            },
          })
        )
      ),
    [items]
  );

  const childrenWithProps = React.Children.map(
    children as JSX.Element,
    (child) =>
      React.cloneElement(child, {
        onClick: handleClick,
        className: `${open ? 'active' : ''}`,
      })
  );

  return (
    <DropdownContainer ref={ref}>
      {childrenWithProps}
      {open && (
        <ItemsContainer position={position}>
          <ItemsList>{renderItems}</ItemsList>
        </ItemsContainer>
      )}
    </DropdownContainer>
  );
};

export { Dropdown, Item };
