/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import Button from 'components/Button';
import Divider from 'components/Divider';
import { Bidder, BiddersConfig } from '@hbcc/api';
import {
  UseFormRegister,
  FieldError,
  DeepMap,
  UseFormSetValue,
} from 'react-hook-form';
import message from 'components/Message';
import { bidderConfigFormRenderer, transformResponse } from 'common/Form/utils';
import {
  FormContent,
  BiddersSelect,
  BidderCard,
  TrashIcon,
  DropdownActions,
} from './styles';

const BiddersConfiguration: React.FC<{
  register: UseFormRegister<any>;
  errors: DeepMap<any, FieldError>;
  setValue: UseFormSetValue<any>;
  bidders: Bidder[];
  biddersConfig?: BiddersConfig[];
  index?: number;
  isSubmitting: boolean;
  isValid: boolean;
}> = ({
  register,
  errors,
  bidders,
  biddersConfig,
  index,
  setValue,
  isSubmitting,
  isValid,
}) => {
  const [selectedBidders, setSelectedBidders] = useState<string[]>([]);

  useEffect(() => {
    if (biddersConfig) {
      const biddersConfiguration = biddersConfig.map((bidder) => bidder.bidder);
      setSelectedBidders(biddersConfiguration);
      // Transform bidders configuration for the form
      const config = transformResponse(bidders, biddersConfig);
      // Translate bidders configuration to form fields
      config.forEach((bidder) => {
        Object.entries(bidder.params).forEach(([key, value]) => {
          const fieldkey =
            index !== undefined
              ? `adUnits.${index}.${bidder.bidder}.${key}`
              : `${bidder.bidder}.${key}`;
          setValue(fieldkey, value);
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bidders, biddersConfig]);

  const isKeyBidderName = (error: DeepMap<any, FieldError>) =>
    bidders.some((bidder) => error[0] === bidder.bidderCode);

  const biddName = (errorCode: string) =>
    bidders.find((bid) => bid.bidderCode === errorCode)?.bidderName;

  const bidderErrorFields = (errorDetails: any): string =>
    Object.keys(errorDetails)
      .map((key) => key)
      .join(', ');

  const generateError = (): string =>
    Object.entries(errors)
      .map((error) => {
        if (isKeyBidderName(error)) {
          return `${biddName(error[0])}: ${bidderErrorFields(error[1])}\n`;
        }
        return '';
      })
      .join('');

  useEffect(() => {
    const isAnyBidderError = Object.entries(errors).some((error) =>
      isKeyBidderName(error)
    );

    if (!isValid && isSubmitting && isAnyBidderError) {
      message.error(`${generateError()}`, { duration: 7 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  const onBidderRemove = (bidderName: string) => {
    setSelectedBidders((prevState) =>
      prevState.filter((bidder) => bidder !== bidderName)
    );
    const fieldkey =
      index !== undefined ? `adUnits.${index}.${bidderName}` : `${bidderName}`;
    setValue(fieldkey, null);
  };

  const id = index !== undefined ? `adUnits-${index}-bidders` : 'bidders';

  return (
    <>
      <FormContent>
        <BiddersSelect
          id={id}
          label="Bidders"
          options={bidders.map((bidder) => ({
            label: bidder.bidderName,
            value: bidder.bidderCode,
          }))}
          value={selectedBidders}
          onChange={(values) => setSelectedBidders(values as string[])}
          showSelectedTags={false}
          actionHandlers
          mode="multiple"
          disableOutsideClick
          dropdownRender={(menu, onCancel, onSave) => (
            <>
              {menu}
              <Divider />
              <DropdownActions>
                <Button onClick={onCancel}>Cancel</Button>
                <Button buttonType="primary" type="button" onClick={onSave}>
                  Save
                </Button>
              </DropdownActions>
            </>
          )}
        />
      </FormContent>
      {selectedBidders.map((bidder) => (
        <BidderCard
          closable
          key={bidder}
          title={
            bidders.find((bid) => bid.bidderCode === bidder)?.bidderName || ''
          }
          defaultOpen={false}
          extra={<TrashIcon onClick={() => onBidderRemove(bidder)} />}
          error={
            index !== undefined
              ? !!errors.adUnits?.[index]?.[bidder]
              : !!errors[bidder]
          }
        >
          <FormContent>
            {bidderConfigFormRenderer(
              bidders.find((bid) => bid.bidderCode === bidder)?.params,
              bidder,
              register,
              errors,
              index
            )}
          </FormContent>
        </BidderCard>
      ))}
    </>
  );
};

export default BiddersConfiguration;
