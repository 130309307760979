import styled from 'styled-components';
import { CloseOutline } from '@easy-eva-icons/react';

export const StyledTopbar = styled.div`
  position: fixed;
  z-index: 1003;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 74px;
  background-color: #fff;
  box-shadow: 6px 6px 16px 0 rgba(217, 226, 236, 0.5);
`;

export const Title = styled.div`
  flex: 1;
  margin-left: 24px;
  color: #04449d;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0;
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 74px;
  height: 74px;
  background-color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const CloseIcon = styled(CloseOutline)`
  width: 24px;
  height: 24px;
  color: #9aa4b0;
`;

export const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1002;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  min-width: 1200px;
  height: 100%;
  background-color: #fff;
`;
