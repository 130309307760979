import styled from 'styled-components';
import { TrashOutline } from '@easy-eva-icons/react';
import Card from 'components/Card';
import Select from 'components/Select';

export const FormContent = styled.div`
  width: 407px;
`;

export const BiddersSelect = styled(Select)`
  margin: 28px 0 34px;
`;

export const DropdownActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 8px 8px;
`;

export const BidderCard = styled(Card)`
  margin-bottom: 8px;
`;

export const TrashIcon = styled(TrashOutline)`
  cursor: pointer;

  :hover {
    color: #3fa1ef;
  }
`;
