/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Component } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { v4 as uuid } from 'uuid';
import { AnimatePresence } from 'framer-motion';
import Message from './Message';
import {
  IMessageManagerProps,
  IMessageManagerState,
  IMessageManagerInstance,
  IMessageContent,
} from './types';

interface IInstance extends IMessageManagerInstance {
  component: MessageManager;
}

class MessageManager extends Component<
  IMessageManagerProps,
  IMessageManagerState
> {
  static newInstance: (
    properties: IMessageManagerProps,
    callback: (instance: IInstance) => void
  ) => void;

  state: IMessageManagerState = {
    messages: [],
  };

  add = (messageProps: IMessageContent): void => {
    const key = uuid();
    const { maxCount } = this.props;
    this.setState((prevState) => {
      const { messages } = prevState;
      if (maxCount && messages.length >= maxCount) {
        return {
          messages: [...messages.slice(1), { ...messageProps, key }],
        };
      }
      return {
        messages: [...messages, { ...messageProps, key }],
      };
    });
  };

  remove = (key: string): void => {
    this.setState((prevState) => ({
      messages: prevState.messages.filter((message) => message.key !== key),
    }));
  };

  render(): JSX.Element {
    return (
      <AnimatePresence>
        {this.state.messages.map((message) => (
          <Message
            key={message.key}
            msgKey={message.key}
            title={message.title}
            description={message.description}
            content={message.content}
            type={message.type}
            duration={message.duration}
            onClose={(key) => {
              this.remove(key);
              if (message.onClose) {
                message.onClose();
              }
            }}
          />
        ))}
      </AnimatePresence>
    );
  }
}

MessageManager.newInstance = function newMessageManagerInstance(
  props,
  callback
): void {
  let portal = document.getElementById('react-message-portal');
  if (!portal) {
    const el = document.createElement('div');
    el.id = 'react-message-portal';
    el.style.cssText = `
      position: fixed;
      width: 100%;
      z-index: 1005;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px 0;
      pointer-events: none;
    `;
    document.body.prepend(el);
    portal = el;
  }

  let called = false;
  function ref(message: MessageManager) {
    if (called) {
      return;
    }

    called = true;
    callback({
      notice(noticeProps) {
        message.add(noticeProps);
      },
      removeNotice(key) {
        message.remove(key);
      },
      component: message,
      destroy() {
        if (portal) {
          unmountComponentAtNode(portal);
          if (portal.parentNode) {
            portal.parentNode.removeChild(portal);
          }
        }
      },
    });
  }

  render(<MessageManager {...props} ref={ref} />, portal);
};

export default MessageManager;
