import React, { createContext, useReducer } from 'react';

export interface IUser {
  email: string;
}

export interface IUserState {
  user: IUser;
  isAuth: boolean;
}

type Action =
  | { type: 'AUTH_USER' }
  | { type: 'LOGOUT_USER' }
  | { type: 'SET_USER_DATA'; payload: { user: IUser } };

interface IContextState {
  state: IUserState;
  dispatch: (action: Action) => void;
}

const initialState: IUserState = {
  user: {
    email: '',
  },
  isAuth: !!localStorage.getItem('isAuth'),
};

const authUser = (): Action => ({
  type: 'AUTH_USER',
});

const logout = (): Action => ({
  type: 'LOGOUT_USER',
});

const setUserData = (user: IUser): Action => ({
  type: 'SET_USER_DATA',
  payload: { user },
});

function userReducer(state: IUserState, action: Action): IUserState {
  switch (action.type) {
    case 'AUTH_USER':
      localStorage.setItem('isAuth', 'true');
      return { ...state, isAuth: true };
    case 'LOGOUT_USER':
      localStorage.removeItem('isAuth');
      return { ...initialState, isAuth: false };
    case 'SET_USER_DATA':
      return { ...state, user: action.payload.user };
    default:
      return state;
  }
}

const UserContext = createContext<IContextState>({
  state: initialState,
  dispatch: () => null,
});

// eslint-disable-next-line import/no-mutable-exports
export let userDispatch: React.Dispatch<Action>;

const UserContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState);
  userDispatch = dispatch;
  Object.freeze(userDispatch);

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContextProvider, UserContext, authUser, logout, setUserData };
