import React from 'react';
import { useLocation } from 'react-router-dom';
import { LibrariesIncludes, AdUnit } from '@hbcc/api';
import { Container } from 'common';
import { Scripts } from './styles';

const SetupInstractions: React.FC = () => {
  const location = useLocation<{
    setupData: {
      publisherId: number;
      containerId: string;
      adUnits: AdUnit[];
      libraries: LibrariesIncludes;
    };
  }>();

  const { publisherId, containerId, adUnits, libraries } =
    location.state.setupData;

  const cdnUrl = process.env.REACT_APP_CDN_URL;
  const src = `${cdnUrl}/publishers/${publisherId}/containers/${containerId}`;
  const scriptPrebid = `<script src="${src}/prebid.js"></script>\n`;
  const scriptGpt = `<script src="${src}/gpt.js"></script>\n`;
  const scriptSdk = `<script src="${src}/sdk.js"></script>
<script>
  const hbcc = new SDK(googletag, pbjs)
  hbcc.run(window.container)
</script>`;

  const showAdUnitSetup = (code: string) => `<div id="${code}">
  <script type='text/javascript'>
    hbcc.display('${code}')
  </script>
</div>`;

  return (
    <Container>
      <h1>Install header-bidding configuration on your website</h1>
      <h4>
        First you need to add these scripts into <strong>&lt;head&gt;</strong>{' '}
        element of your website:
      </h4>
      <Scripts>
        {libraries.prebid && scriptPrebid}
        {libraries.gpt && scriptGpt}
        {scriptSdk}
      </Scripts>
      <h4>
        Next, add defined ad units to your website, in place when given ad unit
        should be displayed:
      </h4>
      <div>
        {adUnits.map((adUnit) => (
          <div key={adUnit.code}>
            <>Ad unit {adUnit.code}</>
            <Scripts>{showAdUnitSetup(adUnit.code)}</Scripts>
          </div>
        ))}
      </div>
    </Container>
  );
};
export default SetupInstractions;
