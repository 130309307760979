import React, { useContext, useMemo } from 'react';
import { useHistory, generatePath } from 'react-router-dom';
import { Header, Container } from 'common';
import { H1 } from 'common/Typography';
import Table from 'components/Table';
import Button from 'components/Button';
import { Publisher } from '@hbcc/api';
import { routes } from 'settings';
import { PublisherContext } from 'contexts/PublisherContext/PublisherContext';
import { setShouldFetch } from 'contexts/PublisherContext/Actions';
import ActionDropdown from './ActionDropdown';

const Publishers: React.FC = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(PublisherContext);
  const { publishersList, isLoading } = state;

  const columns = useMemo(
    () => [
      { Header: "Publisher's name", accessor: 'name' },
      { Header: 'Description', accessor: 'description' },
      {
        Header: 'Bidders',
        accessor: 'configuredBidders',
        Cell: ({ value }: { value: number }) => value,
      },
      {
        Header: () => null,
        accessor: 'actions',
        Cell({ row: { original } }: { row: { original: Publisher } }) {
          return (
            <ActionDropdown
              publisher={original}
              fetchData={() => dispatch(setShouldFetch(true))}
            />
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Container data-testid="publishers-list">
      <Header>
        <H1>Publishers list</H1>
        <Button
          buttonType="primary"
          onClick={() => history.push(generatePath(routes.publisherForm))}
        >
          Add Publisher
        </Button>
      </Header>
      <Table<Publisher>
        loading={isLoading}
        columns={columns}
        data={publishersList}
        pagination
      />
    </Container>
  );
};

export default Publishers;
