import React from 'react';
import {
  EmptyContainer,
  EmptyIcon,
  EmptyDescription,
  EmptyChildren,
} from './styles';
import { IEmpty } from './types';

const Empty: React.FC<IEmpty> = ({
  children,
  description = 'No data',
  padded = false,
}) => (
  <EmptyContainer padded={padded}>
    <EmptyIcon />
    <EmptyDescription>{description}</EmptyDescription>
    {children && <EmptyChildren>{children}</EmptyChildren>}
  </EmptyContainer>
);

export default Empty;
