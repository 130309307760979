import styled, { css } from 'styled-components';
import { DividerType } from './types';

// eslint-disable-next-line import/prefer-default-export
export const DividerLine = styled.div<{ type: DividerType; compact: boolean }>`
  ${({ type }) =>
    type === 'horizontal' &&
    css`
      border-top: 1px solid #cad2d9;
      margin: 8px 0;
    `}

  ${({ type }) =>
    type === 'vertical' &&
    css`
      position: relative;
      top: -0.06em;
      display: inline-block;
      margin: 0 8px;
      height: 0.9em;
      border-left: 1px solid #cad2d9;
      vertical-align: middle;
    `}

  ${({ compact }) =>
    compact &&
    css`
      margin: 0;
    `}
`;
