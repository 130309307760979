/* eslint-disable @typescript-eslint/no-explicit-any */
import MessageManager from './MessageManager';
import { IMessageAPI, IMessageConfig, MessageType } from './types';

// eslint-disable-next-line import/no-mutable-exports
let messageInstance: any = null;
MessageManager.newInstance({ maxCount: 5 }, (n) => {
  messageInstance = n;
});

const api: any = {};

api.open = (title: string, options?: IMessageConfig) =>
  messageInstance.notice({ title, type: 'default', ...options });

(['success', 'info', 'warning', 'error'] as MessageType[]).forEach((type) => {
  api[type] = (title: string, options?: IMessageConfig) =>
    messageInstance.notice({ title, type, ...options });
});

api.warn = api.warning;

export default api as IMessageAPI;
export { messageInstance as instance };
