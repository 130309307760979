import React from 'react';

import { Error } from './styles';
import { ErrorsType } from './types';

// eslint-disable-next-line import/prefer-default-export
export const renderErrors = (errors: ErrorsType): JSX.Element => {
  if (Array.isArray(errors)) {
    return (
      <>
        {errors.map((error, idx) => (
          <Error key={idx}>{error}</Error>
        ))}
      </>
    );
  }
  return <Error>{errors}</Error>;
};
