import React from 'react';
import { Container, Indicator, Label } from './styles';
import { ISpin } from './types';

const Spin: React.FC<ISpin> = ({ color, label, dataTestid, ...rest }) => (
  <Container data-testid={dataTestid}>
    <Indicator color={color} {...rest} />
    {label && <Label color={color}>{label}</Label>}
  </Container>
);

export default Spin;
