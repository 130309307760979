import styled, { css } from 'styled-components';

export const SwitchContainer = styled.div<{ topLabel?: boolean }>`
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  ${({ topLabel }) =>
    topLabel &&
    css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 0 0 16px;
    `}
`;

export const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 44px;
  height: 22px;
`;

export const Slider = styled.span<{ disabled?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: inline-block;
  background-color: #9aa4b0;
  border-radius: 34px;
  cursor: pointer;
  transition: 0.4s;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      background-color: #cad2d9;
    `}

  &:before {
    position: absolute;
    bottom: 2px;
    left: 2px;
    width: 18px;
    height: 18px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
    content: '';

    ${({ disabled }) =>
      disabled &&
      css`
        background-color: #f5f7f9;
      `}
  }
`;

export const Checkbox = styled.input<{ disabled?: boolean }>`
  width: 0;
  height: 0;
  opacity: 0;

  &:checked + ${Slider} {
    background-color: #47a3f3;

    ${({ disabled }) =>
      disabled &&
      css`
        opacity: 0.4;
      `}
  }

  &:checked + ${Slider}:before {
    transform: translateX(22px);
  }
`;

export const Label = styled.span`
  margin-left: 8px;
  color: #616d7c;
  font-size: 14px;
  letter-spacing: 0;
`;
