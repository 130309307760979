/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import Dropdown, { Item } from 'components/Dropdown';
import Button from 'components/Button';
import Divider from 'components/Divider';
import { routes } from 'settings';
import { PublisherContext } from 'contexts/PublisherContext/PublisherContext';
import { setCurrentPublisher } from 'contexts/PublisherContext/Actions';
import { logout, UserContext } from 'contexts/UserContext';
import {
  EmailItem,
  Header,
  Logo,
  Settings,
  LogOutIcon,
  SettingsIcon,
  PublishersSelect,
  ActionButton,
  Container,
  DropdownActions,
} from './styles';

const Navbar: React.FC = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(PublisherContext);
  const { currentPublisher, publishersList, isLoading } = state;
  const userContext = useContext(UserContext);
  const { email } = userContext.state.user;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onClick = (key: any) => {
    switch (key) {
      case 'log-out':
        userContext.dispatch(logout());
        break;
      default:
        break;
    }
  };

  const setPublisher = (id: number): void => {
    const selectedPublisher = publishersList.find((item) => item.id === id);
    if (currentPublisher?.id !== selectedPublisher?.id) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dispatch(setCurrentPublisher(selectedPublisher!));
    }
  };

  // TODO: fix interfaces and onClick element
  const settingsItems = (
    <div onClick={(key) => onClick(key)}>
      <EmailItem key="email" data-testid="email">
        {email}
      </EmailItem>
      <Divider />
      <Item key="log-out" data-testid="log-out">
        <LogOutIcon />
        Log out
      </Item>
    </div>
  );

  return (
    <Header>
      <Container>
        <Logo />
        <PublishersSelect
          id="publishers"
          options={publishersList.map((publisher) => ({
            label: publisher.name,
            value: String(publisher.id),
          }))}
          placeholder="Select publisher"
          value={[String(currentPublisher?.id)]}
          onChange={(value) => {
            const id = Number(value);
            setPublisher(id);
          }}
          showSelectedTags={false}
          loading={isLoading}
          showSearch
          dropdownRender={(menu, onClose) => (
            <>
              {menu}
              <Divider />
              <DropdownActions>
                <ActionButton
                  buttonType="primary"
                  onClick={() => {
                    history.push(routes.publishers);
                    onClose();
                  }}
                >
                  Go to Publishers List
                </ActionButton>
              </DropdownActions>
            </>
          )}
        />
      </Container>
      <Settings>
        <Dropdown items={settingsItems} position="right">
          <Button buttonType="transparent" shape="circle">
            <SettingsIcon />
          </Button>
        </Dropdown>
      </Settings>
    </Header>
  );
};

export { Navbar as default };
