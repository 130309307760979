import styled from 'styled-components';
import { InfoOutline } from '@easy-eva-icons/react';
import Divider from 'components/Divider';

export const FormContent = styled.div`
  width: 407px;
`;

export const HelpLabelContainer = styled.span`
  display: inline-flex;
  align-items: center;
`;

export const HelpLabel = styled.span`
  text-transform: capitalize;
`;

export const ActionsDivider = styled(Divider)`
  margin: 40px 0 24px;
`;

export const Required = styled.span`
  margin-left: 4px;
`;

export const InfoIcon = styled(InfoOutline)`
  width: 16px;
  height: 16px;
  margin-left: 2px;
`;
