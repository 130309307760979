import React, { useRef, useState } from 'react';
import { InputContainer, InputElement, Label } from '../Input/styles';
import Tag from '../Tag';
import { InputTagField, InputField } from './styles';
import { ITagSelect } from './types';

const TagSelect: React.FC<ITagSelect> = ({
  label,
  id,
  defaultValues,
  validateValue,
  onChange,
}: {
  label: string;
  id: string;
  defaultValues?: string[];
  validateValue?: (v: React.ReactNode | string) => boolean;
  onChange?: (tags: string[]) => void;
}) => {
  const [tags, setTags] = useState<string[]>(defaultValues || []);
  const [newTag, setNewTag] = useState<string>('');
  const [invalidValue, setInvalidValue] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const addTag = (e: React.KeyboardEvent) => {
    if (e.key === 'Backspace' && newTag === '') {
      setTags(tags.slice(0, tags.length - 1));
      return;
    }
    if (e.key !== 'Enter') return;

    e.preventDefault();

    if (!newTag) return;

    if (validateValue && !validateValue(newTag)) {
      setInvalidValue(true);
      return;
    }

    if (tags.some((t) => t === newTag)) {
      setNewTag('');
      return;
    }

    const newTags = [...tags, newTag];

    setTags(newTags);
    setNewTag('');
    if (onChange) onChange(newTags);
  };

  const deleteTag = (_: unknown, tag: React.ReactNode | string) => {
    const newTags = tags.filter((t: string) => t !== tag);
    setTags(newTags);
    if (onChange) onChange(newTags);
  };

  return (
    <InputContainer>
      {label && <Label htmlFor={id}>{label}</Label>}
      <InputElement>
        <InputField data-testid={id} onClick={() => inputRef.current?.focus()}>
          {tags.map((t) => (
            <Tag
              data-testid="tag-item"
              key={t}
              closable={true}
              onClose={deleteTag}
            >
              {t}
            </Tag>
          ))}
          <InputTagField
            id={id}
            data-testid={`${id}-input`}
            spellCheck="false"
            style={invalidValue ? { color: 'red' } : undefined}
            onChange={(e) => {
              setInvalidValue(false);
              setNewTag(e.target.value);
            }}
            ref={inputRef}
            value={newTag}
            onKeyDown={addTag}
          />
        </InputField>
      </InputElement>
    </InputContainer>
  );
};

export default TagSelect;
