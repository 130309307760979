import React, { FC } from 'react';
import { ContainerListItem } from '../../../../../api/src/containers';
import { FilledTag } from '../styles';

export const BlueTag: FC = ({ children }) => (
  <FilledTag visible={true} variant="blue">
    {children}
  </FilledTag>
);

export const YellowTag: FC = ({ children }) => (
  <FilledTag visible={true} variant="yellow">
    {children}
  </FilledTag>
);

function isContainerPublished(container: ContainerListItem): boolean {
  return container.status === 'published';
}

function isContainerGenerating(container: ContainerListItem): boolean {
  return container.status === 'generating';
}

const StatusTag: FC<{ container: ContainerListItem }> = ({
  container,
  children,
}) => (
  <>
    {isContainerPublished(container) && <BlueTag>{children}</BlueTag>}
    {isContainerGenerating(container) && <YellowTag>{children}</YellowTag>}
  </>
);

export default StatusTag;
