import { Publisher } from '@hbcc/api';

export const FETCH_PUBLISHERS_LIST = 'FETCH_PUBLISHERS_LIST';
export const SET_CURRENT_PUBLISHER = 'SET_CURRENT_PUBLISHER';
export const SET_PUBLISHERS_IS_LOADING = 'SET_PUBLISHERS_IS_LOADING';
export const SET_SHOULD_FETCH = 'SET_SHOULD_FETCH';

export type PublishersAction =
  | {
      type: 'FETCH_PUBLISHERS_LIST';
      payload: Publisher[];
    }
  | {
      type: 'SET_CURRENT_PUBLISHER';
      payload: Publisher;
    }
  | {
      type: 'SET_PUBLISHERS_IS_LOADING';
      payload: boolean;
    }
  | {
      type: 'SET_SHOULD_FETCH';
      payload: boolean;
    };

const fetchPublishers = (publishersList: Publisher[]): PublishersAction => ({
  type: 'FETCH_PUBLISHERS_LIST',
  payload: publishersList,
});

const setCurrentPublisher = (
  currentPublisher: Publisher
): PublishersAction => ({
  type: 'SET_CURRENT_PUBLISHER',
  payload: currentPublisher,
});

const setPublishersIsLoading = (isLoading: boolean): PublishersAction => ({
  type: 'SET_PUBLISHERS_IS_LOADING',
  payload: isLoading,
});

const setShouldFetch = (shouldFetch: boolean): PublishersAction => ({
  type: 'SET_SHOULD_FETCH',
  payload: shouldFetch,
});

export {
  fetchPublishers,
  setCurrentPublisher,
  setPublishersIsLoading,
  setShouldFetch,
};
