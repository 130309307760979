import apiClient from 'helpers/apiClient';
import { AxiosResponse } from 'axios';
import { apiUrl } from 'settings';
import { Bidder } from '@hbcc/api';

export const baseUrl = `${apiUrl}/bidders`;

const getAll = (): Promise<AxiosResponse<Bidder[]>> =>
  apiClient.get<Bidder[]>(baseUrl);

export default {
  getAll,
};
