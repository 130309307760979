import styled from 'styled-components';
import { Trash2Outline, EditOutline } from '@easy-eva-icons/react';
import { itemIcon } from 'components/Dropdown/styles';

export const TrashIcon = styled(Trash2Outline)`
  ${itemIcon}
`;

export const EditIcon = styled(EditOutline)`
  ${itemIcon}
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
