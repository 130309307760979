import apiClient from 'helpers/apiClient';
import { AxiosResponse } from 'axios';
import { apiUrl } from 'settings';
import { ContainerListItem, CreateUpdateContainerBody } from '@hbcc/api';

interface PublisherResponse extends CreateUpdateContainerBody {
  id: number;
  containerId: string;
  createdAt: string;
  deletedAt: string | null;
  failsafeTimeout: number;
  includeGpt: boolean;
  includePrebid: boolean;
  prebidTimeout: number;
}

export const baseUrl = `${apiUrl}/publishers`;

const getAll = (
  publisherId: number
): Promise<AxiosResponse<ContainerListItem[]>> =>
  apiClient.get<ContainerListItem[]>(`${baseUrl}/${publisherId}/containers`, {
    headers: {
      'publisher-id': `${publisherId}`,
    },
  });

const getContainer = (
  publisherId: number,
  containerId: string
): Promise<AxiosResponse<PublisherResponse>> =>
  apiClient.get<PublisherResponse>(
    `${baseUrl}/${publisherId}/containers/${containerId}`
  );

const createContainer = (
  publisherId: number,
  data: CreateUpdateContainerBody
): Promise<AxiosResponse<ContainerListItem>> =>
  apiClient.post<ContainerListItem>(
    `${baseUrl}/${publisherId}/containers`,
    data
  );

const updateContainer = (
  publisherId: number,
  containerId: string,
  data: CreateUpdateContainerBody
): Promise<AxiosResponse<ContainerListItem>> =>
  apiClient.put<ContainerListItem>(
    `${baseUrl}/${publisherId}/containers/${containerId}`,
    data
  );

const deleteContainer = (
  publisherId: number,
  containerId: string
): Promise<AxiosResponse<ContainerListItem>> =>
  apiClient.delete(`${baseUrl}/${publisherId}/containers/${containerId}`);

export default {
  getAll,
  getContainer,
  createContainer,
  updateContainer,
  deleteContainer,
};
