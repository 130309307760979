import styled from 'styled-components';

const AvatarContainer = styled.div`
  display: inline-block;
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  background-color: #fff;
  border: 1px solid rgba(202, 210, 217, 0.25);
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 #d9e2ec;
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
`;

export { AvatarContainer, AvatarImage };
