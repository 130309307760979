import axios from 'axios';
import message from 'components/Message';
import { fetchTokens } from 'services/Auth/CognitoAuth';

const apiClient = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  async (config) => {
    const { jwtToken } = await fetchTokens();
    if (jwtToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${jwtToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      // Message displayed when backend server is turned off.
      message.error('Network error.');
    } else {
      const {
        response: { status },
      } = error;
      if (status === 404) {
        message.warning('Requested resource is not available.');
      }
    }
    // Sentry will catch only events with uncaught promises
    return Promise.reject(error);
  }
);

export { apiClient as default, axios };
