import React, { useEffect } from 'react';
import {
  CheckmarkCircle2,
  Info,
  AlertCircle,
  AlertTriangle,
} from '@easy-eva-icons/react';
import {
  MessageContainer,
  MessageIcon,
  MessageContent,
  MessageTitle,
  MessageDescription,
  MessageClose,
  CloseIcon,
} from './styles';
import { IMessage } from './types';

const icons = {
  default: <CheckmarkCircle2 />,
  info: <Info />,
  success: <CheckmarkCircle2 />,
  warning: <AlertTriangle />,
  error: <AlertCircle />,
};

const Message: React.FC<IMessage> = ({
  msgKey,
  title,
  description,
  content,
  type = 'default',
  duration = 2,
  onClose,
}) => {
  const handleClose = (e?: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    if (e) {
      e.stopPropagation();
    }
    if (onClose) {
      onClose(msgKey);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleClose();
    }, duration * 1000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MessageContainer
      data-testid={`message-${type}`}
      initial={{ opacity: 0, y: 50, scale: 0.3 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
      type={type}
    >
      <MessageIcon type={type}>{icons[type]}</MessageIcon>
      <MessageContent type={type}>
        {content || (
          <>
            <MessageTitle>{title}</MessageTitle>
            {description && (
              <MessageDescription>{description}</MessageDescription>
            )}
          </>
        )}
      </MessageContent>
      <MessageClose>
        <CloseIcon
          data-testid="close-message"
          onClick={(e) => handleClose(e)}
          type={type}
        />
      </MessageClose>
    </MessageContainer>
  );
};

export default Message;
