import Button from 'components/Button';
import Card from 'components/Card';
import Checkbox from 'components/Checkbox';
import Input from 'components/Input';
import Select from 'components/Select';
import styled from 'styled-components';
import { Trash2Outline, PlusOutline } from '@easy-eva-icons/react';

export const Libraries = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LibrariesCheckbox = styled(Checkbox)`
  margin-top: 16px;
`;

export const MarginCard = styled(Card)`
  margin: 32px 0;
`;

export const List = styled.ol`
  list-style: none;
  counter-reset: my-awesome-counter;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 32px;
  counter-increment: my-awesome-counter;

  &::before {
    margin-right: 10px;
    color: #979fb6;
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0;
    content: counter(my-awesome-counter) '. ';
  }
`;

export const BasicConfiguration = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DeleteButton = styled(Button)`
  margin-top: 18px;
  margin-left: 32px;
`;

export const InputText = styled(Input)`
  width: 407px;
`;

export const InputSuffix = styled(Input)`
  width: 168px;
`;

export const SizeSelect = styled(Select)`
  width: 407px;
  margin: 0;
`;

export const AdUnitsLabel = styled.span`
  color: #9aa4b0;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 1.13px;
`;

export const PriceBucketContainer = styled.div`
  width: 214px;
`;

export const TitleBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

// Overlay modal sidebar layout
const SidebarWidth = '250px';
const SidebarHeight = '74px';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding-top: ${SidebarHeight};
  padding-left: ${SidebarWidth};
  background: #f5f6fa;
`;

export const FormContainer = styled.div`
  width: 100%;
  padding: 35px 72px 35px 60px;
`;

export const Separator = styled.div`
  height: 1px;
  margin: 16px 32px;
  background-color: #cad2d9;
`;

export const TrashIcon = styled(Trash2Outline)`
  width: 16px;
  height: 16px;
  color: #979797;
  cursor: pointer;

  :hover {
    color: #3fa1ef;
  }
`;

export const PlusIcon = styled(PlusOutline)`
  width: 14px;
  height: 14px;
  margin-right: 4px;
  color: #04449d;
`;

export const AddAdUnitButton = styled(Button)`
  display: flex;
  color: #04449d;
`;
