import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { ReactComponent as Emblem } from 'assets/emblem.svg';
import Input from 'components/Input';
import {
  Container,
  Head,
  CompanyAvatar,
  Header,
  Form,
  SubmitButton,
} from './styles';

const passwordRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*\-(){}[\]:;<>,./~_+=|\\]).{8,}$/;

type PasswordChangeInputs = {
  newPassword: string;
  repeatPassword: string;
};

interface IPasswordSetupForm {
  onPasswordSetup: (newPassword: string) => void;
}

const PasswordSetupForm: React.FC<IPasswordSetupForm> = ({
  onPasswordSetup,
}) => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isDirty, isValid },
  } = useForm<PasswordChangeInputs>({ mode: 'onChange' });

  const onSubmit: SubmitHandler<PasswordChangeInputs> = async (data) => {
    onPasswordSetup(data.newPassword);
  };

  return (
    <Container>
      <Head>
        <CompanyAvatar svg={<Emblem />} />
        <Header>Set new password</Header>
      </Head>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          data-testid="newPassword"
          placeholder="Type new password"
          type="password"
          autoComplete="new-password"
          {...register('newPassword', {
            required: 'This field is required!',
            pattern: {
              value: passwordRegex,
              message:
                'Password should be min 8 characters long and contain 1 upper case letter, 1 number, 1 special character.',
            },
          })}
          errors={errors.newPassword?.message}
        />
        <Input
          data-testid="repeatPassword"
          placeholder="Repeat new password"
          type="password"
          autoComplete="new-password"
          {...register('repeatPassword', {
            required: 'This field is required!',
            validate: (value) =>
              value === getValues('newPassword') || 'Password mismatch!',
          })}
          errors={errors.repeatPassword?.message}
        />
        <SubmitButton
          data-testid="updatePassword"
          type="submit"
          disabled={!isDirty || !isValid}
        >
          Update password
        </SubmitButton>
      </Form>
    </Container>
  );
};

export default PasswordSetupForm;
