/* eslint-disable react/jsx-key */
import React from 'react';
import { useTable, usePagination } from 'react-table';
import Spin from 'components/Spin';
import Empty from 'components/Empty';
import {
  Loading,
  TableWrapper,
  Table as TableContainer,
  TBody,
  THead,
  THeadRow,
  THeadCell,
  TBodyRow,
  TBodyCell,
  Pagination,
  PaginationButton,
  Ellipsis,
} from './styles';
import { ITable } from './types';

function Table<T extends Record<keyof T, unknown>>({
  columns,
  data,
  pagination = false,
  loading = false,
}: ITable<T>): JSX.Element {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    // Pagination tools
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    gotoPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: !pagination && data.length ? data.length : 10,
      },
    },
    usePagination
  );

  const renderPagination = React.useMemo(() => {
    if (!page.length) {
      return null;
    }

    const left = pageIndex - 1;
    const right = pageIndex + 2;
    const range = [];
    const rangeWithDots = [] as JSX.Element[];
    let l = 0;

    for (let i = 1; i <= pageOptions.length; i += 1) {
      if (i === 1 || i === pageOptions.length || (i >= left && i <= right)) {
        range.push(i);
      } else if (i < left) {
        i = left - 1;
      } else if (i > right) {
        range.push(pageOptions.length);
        break;
      }
    }

    range.forEach((i, j) => {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(
            <PaginationButton
              key={`special-${j}`}
              onClick={() => gotoPage(l)}
              active={l + 1 === pageIndex}
            >
              {l + 1}
            </PaginationButton>
          );
        } else if (i - l !== 1) {
          rangeWithDots.push(<Ellipsis key={`ellipsis-${j}`}>...</Ellipsis>);
        }
      }
      rangeWithDots.push(
        <PaginationButton
          key={j}
          onClick={() => gotoPage(i - 1)}
          active={i - 1 === pageIndex}
        >
          {i}
        </PaginationButton>
      );
      l = i;
    });

    return (
      <Pagination>
        <PaginationButton
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {'<'}
        </PaginationButton>
        {rangeWithDots}
        <PaginationButton onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </PaginationButton>
      </Pagination>
    );
  }, [
    page,
    pageIndex,
    pageOptions,
    gotoPage,
    canNextPage,
    canPreviousPage,
    nextPage,
    previousPage,
  ]);

  return (
    <TableWrapper>
      {loading && (
        <Loading>
          <Spin color="#47A3F3" data-testid="table-spinner" />
        </Loading>
      )}
      <TableContainer isLoading={loading} {...getTableProps()}>
        <THead>
          {headerGroups.map((headerGroup) => (
            <THeadRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <THeadCell {...column.getHeaderProps()}>
                  {column.render('Header')}
                </THeadCell>
              ))}
            </THeadRow>
          ))}
        </THead>
        <TBody {...getTableBodyProps()}>
          {page.length ? (
            page.map((row) => {
              prepareRow(row);
              return (
                <TBodyRow {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <TBodyCell {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </TBodyCell>
                  ))}
                </TBodyRow>
              );
            })
          ) : (
            <TBodyRow empty>
              <TBodyCell colSpan={columns.length}>
                <Empty />
              </TBodyCell>
            </TBodyRow>
          )}
        </TBody>
      </TableContainer>
      {pagination && renderPagination}
    </TableWrapper>
  );
}

export default Table;
