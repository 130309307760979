import React from 'react';

import { routes } from 'settings';
import { Sidebar, NavContainer, NavItem } from './styles';

const SideNavbar: React.FC = () => (
  <Sidebar>
    <NavContainer>
      <NavItem to={routes.publishers}>Publishers</NavItem>
      <NavItem data-testid="containers-navitem" to={routes.containers}>
        Containers
      </NavItem>
    </NavContainer>
  </Sidebar>
);

export default SideNavbar;
