/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { BidderParamAttributes, Bidder, BiddersConfig } from '@hbcc/api';
import { UseFormRegister, DeepMap } from 'react-hook-form';
import Input from 'components/Input';
import Switch from 'components/Switch';
import TextArea from 'components/TextArea';
import {
  HelpLabel,
  HelpLabelContainer,
  InfoIcon,
  LabelTooltip,
  Required,
} from './styles';

export function isJSON(str: string): boolean {
  try {
    const obj = JSON.parse(str);
    if (obj && typeof obj === 'object' && obj !== null) {
      return true;
    }
  } catch (err) {
    // nothing to do here.
  }
  return false;
}

export const helpLabel = (
  label: string,
  help: string,
  required: boolean
): JSX.Element => (
  <HelpLabelContainer>
    <HelpLabel>{label}</HelpLabel>
    {required && <Required>(required)</Required>}
    <LabelTooltip content={help} data-testid="help-tooltip">
      <InfoIcon />
    </LabelTooltip>
  </HelpLabelContainer>
);

export type FieldType =
  | 'string'
  | 'integer'
  | 'float'
  | 'boolean'
  | 'object'
  | 'string[]'
  | 'integer[]';

export const parseField = (type: FieldType, fieldValue: any): any => {
  switch (type) {
    case 'string[]':
      return (fieldValue as string)
        .split(',')
        .map((v) => v.trim())
        .filter((v) => v !== '');
    case 'integer[]':
      return (fieldValue as string)
        .split(',')
        .map((v) => v.trim())
        .filter((v) => v !== '')
        .map((v) => Number.parseInt(v, 10));
    case 'object':
      return JSON.parse(fieldValue);
    case 'float':
      return Number.parseFloat(fieldValue);
    case 'integer':
      return Number.parseInt(fieldValue, 10);
    case 'boolean':
    case 'string':
    default:
      return fieldValue;
  }
};

export const transformField = (
  type: FieldType,
  fieldValue: any
): string | boolean => {
  switch (type) {
    case 'string[]':
    case 'integer[]':
      return fieldValue.join(', ');
    case 'object':
      return JSON.stringify(fieldValue);
    case 'float':
      return fieldValue.toString();
    case 'integer':
      return fieldValue.toString();
    case 'boolean':
    case 'string':
    default:
      return fieldValue;
  }
};

export const transformResponse = (
  bidders: Bidder[],
  biddersConfiguration: BiddersConfig[]
): { bidder: string; params: Record<string, unknown> }[] =>
  biddersConfiguration.map((bidder) => {
    const paramsTypes = {} as { [key: string]: FieldType };
    Object.entries(
      bidders.find((bid) => bid.bidderCode === bidder.bidder)?.params || {}
    ).forEach(([key, value]) => {
      paramsTypes[key] = value.type as FieldType;
    });
    const params = {} as { [key: string]: string | boolean };
    Object.entries(bidder.params).forEach(([key, value]) => {
      params[key] = transformField(paramsTypes[key], value);
    });
    return { bidder: bidder.bidder, params };
  });

export const parseForm = (
  bidders: Bidder[],
  rest: any
): { bidder: string; params: Record<string, unknown> }[] =>
  Object.entries(rest).map(([bidder, params]) => {
    const paramsTypes = {} as { [key: string]: FieldType };
    Object.entries(
      bidders.find((bid) => bid.bidderCode === bidder)?.params || {}
    ).forEach(([key, value]) => {
      paramsTypes[key] = value.type as FieldType;
    });
    const newParams = {} as { [key: string]: any };
    Object.entries(params as Record<string, unknown>).forEach(
      ([type, param]) => {
        if (param !== '') {
          newParams[type] = parseField(paramsTypes[type], param);
        }
      }
    );
    return { bidder, params: newParams };
  });

export const bidderConfigFormRenderer = (
  params: { [key: string]: BidderParamAttributes } | undefined,
  bidder: string,
  register: UseFormRegister<any>,
  errors: DeepMap<any, any>,
  index?: number
): JSX.Element[] | null => {
  if (!params) {
    return null;
  }

  const sortedParams = Object.entries(params).sort(
    (a, b) => +b[1].required - +a[1].required || a[0].localeCompare(b[0])
  );

  return sortedParams.map(([key, value]) => {
    const fieldName =
      index !== undefined
        ? `adUnits.${index}.${bidder}.${key}`
        : `${bidder}.${key}`;

    const fieldKey = index !== undefined ? `${index}.${key}` : key;
    const error =
      index !== undefined
        ? errors.adUnits?.[index]?.[bidder]?.[key]?.message
        : errors[bidder]?.[key]?.message;

    switch (value.type) {
      case 'boolean':
        return (
          <Switch
            key={fieldKey}
            data-testid={fieldName}
            {...register(fieldName, {
              shouldUnregister: false,
            })}
            topLabel={helpLabel(`${key}`, value.description, value.required)}
          />
        );
      case 'object':
        return (
          <TextArea
            key={fieldKey}
            label={helpLabel(`${key}`, value.description, value.required)}
            data-testid={fieldName}
            {...register(fieldName, {
              required: {
                value: value.required,
                message: `${key} is required.`,
              },
              validate: {
                isJSON: (content) =>
                  content === '' ||
                  isJSON(content) ||
                  `${key} is not a JSON object.`,
              },
              shouldUnregister: false,
            })}
            rows={1}
            errors={error}
          />
        );
      case 'integer':
        return (
          <Input
            key={fieldKey}
            label={helpLabel(`${key}`, value.description, value.required)}
            data-testid={fieldName}
            {...register(fieldName, {
              required: {
                value: value.required,
                message: `${key} is required.`,
              },
              shouldUnregister: false,
            })}
            type="number"
            errors={error}
          />
        );
      case 'float':
        return (
          <Input
            key={fieldKey}
            label={helpLabel(`${key}`, value.description, value.required)}
            data-testid={fieldName}
            {...register(fieldName, {
              required: {
                value: value.required,
                message: `${key} is required.`,
              },
              shouldUnregister: false,
            })}
            type="number"
            step="0.01"
            errors={error}
          />
        );
      case 'string':
        return (
          <Input
            key={fieldKey}
            data-testid={fieldName}
            {...register(fieldName, {
              required: {
                value: value.required,
                message: `${key} is required.`,
              },
              shouldUnregister: false,
            })}
            label={helpLabel(`${key}`, value.description, value.required)}
            errors={error}
          />
        );
      case 'integer[]':
        return (
          <TextArea
            key={fieldKey}
            label={helpLabel(`${key}`, value.description, value.required)}
            data-testid={fieldName}
            {...register(fieldName, {
              required: {
                value: value.required,
                message: `${key} is required.`,
              },
              shouldUnregister: false,
            })}
            rows={1}
            errors={error}
          />
        );
      case 'string[]':
        return (
          <TextArea
            key={fieldKey}
            label={helpLabel(`${key}`, value.description, value.required)}
            data-testid={fieldName}
            {...register(fieldName, {
              required: {
                value: value.required,
                message: `${key} is required.`,
              },
              shouldUnregister: false,
            })}
            rows={1}
            errors={error}
          />
        );
      default:
        return (
          <div
            key={fieldKey}
          >{`${key} (${value.type}) field is not supported yet.`}</div>
        );
    }
  });
};
