import React, { createContext, useEffect, useReducer } from 'react';

import publisherService from 'services/Publisher/publisher.service';
import message from 'components/Message';
import {
  publisherReducer,
  initialState,
  PublisherState,
  publisherPlaceholder,
} from './Reducer';
import {
  fetchPublishers,
  PublishersAction,
  setCurrentPublisher,
  setPublishersIsLoading,
} from './Actions';

interface IPublisherContext {
  state: PublisherState;
  dispatch: (action: PublishersAction) => void;
}

export const PublisherContext = createContext<IPublisherContext>({
  state: initialState,
  dispatch: () => null,
});

export const PublisherContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(publisherReducer, initialState);
  const { shouldFetch, currentPublisher } = state;

  const fetchPublishersData = (): void => {
    dispatch(setPublishersIsLoading(true));

    publisherService
      .getAll()
      .then((response) => {
        const publishersList = response.data;
        const isCurrentPublisherInList = publishersList.find(
          (publisher) => publisher.id === currentPublisher.id
        );
        dispatch(fetchPublishers(publishersList));
        if (
          (publishersList.length > 0 && currentPublisher.id === 0) ||
          !isCurrentPublisherInList
        ) {
          dispatch(setCurrentPublisher(publishersList[0]));
        }
        if (publishersList.length === 0) {
          dispatch(setCurrentPublisher(publisherPlaceholder));
        }
        dispatch(setPublishersIsLoading(false));
      })
      .catch(() =>
        message.error('Error occured while fetching publishers list.')
      );
  };

  useEffect(() => {
    if (shouldFetch) fetchPublishersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetch]);

  return (
    <PublisherContext.Provider value={{ state, dispatch }}>
      {children}
    </PublisherContext.Provider>
  );
};
