import styled from 'styled-components';
import { ReactComponent as CompanyLogo } from 'assets/logo.svg';
import { Item, itemIcon } from 'components/Dropdown/styles';
import { SettingsOutline, LogOutOutline } from '@easy-eva-icons/react';
import Select from 'components/Select';
import Button from 'components/Button';

export const LogOutIcon = styled(LogOutOutline)`
  ${itemIcon}
`;

export const SettingsItemIcon = styled(SettingsOutline)`
  ${itemIcon}
`;

export const SettingsIcon = styled(SettingsOutline)`
  width: 24px;
  height: 24px;
`;

export const Header = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 75px;
  background-color: #fff;
  box-shadow: 6px 6px 16px 0 rgba(217, 226, 236, 0.5);
`;

export const Logo = styled(CompanyLogo)`
  height: 35px;
  margin-left: 24px;
`;

export const Settings = styled.div`
  margin-right: 60px;
`;

export const EmailItem = styled(Item)`
  color: #9aa4b0;
  cursor: default;

  &:hover {
    background-color: #ffffff;
  }
`;

export const PublishersSelect = styled(Select)`
  position: absolute;
  left: 310px;
  width: 340px;
`;

export const ActionButton = styled(Button)`
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const DropdownActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 8px 8px;
`;
