/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { MoreHorizontalOutline } from '@easy-eva-icons/react';
import publisherService from 'services/Publisher/publisher.service';
import { routes } from 'settings';

import Button from 'components/Button';
import Dropdown, { Item } from 'components/Dropdown';

import { confirm } from 'components/Modal';
import message from 'components/Message';
import { EditIcon, TrashIcon, Container } from './styles';
import { IDropdown } from './types';

export function deletePublisher(id: number, fetchData: () => void): void {
  publisherService
    .deletePublisher(id)
    .then(() => {
      message.success('Publisher was successfully deleted.');
      fetchData();
    })
    .catch(() => message.error('Something went wrong.'));
}

const ActionDropdown: React.FC<IDropdown> = ({ publisher, fetchData }) => {
  const history = useHistory();
  const { id } = publisher;
  const deleteModal = () =>
    confirm({
      onOk: () => deletePublisher(id, fetchData),
      title: `Delete ${publisher.name}`,
      description: (
        <>
          Are you sure you want to delete <strong>{publisher.name}</strong>?
        </>
      ),
    });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onClick = (key: any) => {
    switch (key) {
      case 'edit':
        history.push(generatePath(routes.publisherForm, { publisherId: id }));
        break;
      case 'delete':
        deleteModal();
        break;
      default:
        break;
    }
  };

  const items = (
    <div onClick={(key) => onClick(key)}>
      <Item key="edit" data-testid="edit">
        <EditIcon />
        Edit
      </Item>
      <Item key="delete" data-testid="delete">
        <TrashIcon />
        Delete
      </Item>
    </div>
  );

  return (
    <Container>
      <Dropdown items={items} position="right">
        <Button buttonType="transparent" shape="square">
          <MoreHorizontalOutline />
        </Button>
      </Dropdown>
    </Container>
  );
};

export default ActionDropdown;
