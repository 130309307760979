import { Publisher } from '@hbcc/api';
import {
  PublishersAction,
  FETCH_PUBLISHERS_LIST,
  SET_CURRENT_PUBLISHER,
  SET_PUBLISHERS_IS_LOADING,
  SET_SHOULD_FETCH,
} from './Actions';

export interface PublisherState {
  publishersList: Publisher[];
  currentPublisher: Publisher;
  isLoading: boolean;
  shouldFetch: boolean;
}

export const publisherPlaceholder: Publisher = {
  id: 0,
  name: '',
  description: '',
  configuredBidders: 0,
  gamNetworkId: 0,
};

export const initialState: PublisherState = {
  currentPublisher: publisherPlaceholder,
  publishersList: [],
  isLoading: false,
  shouldFetch: true,
};

export function publisherReducer(
  state: PublisherState,
  action: PublishersAction
): PublisherState {
  switch (action.type) {
    case FETCH_PUBLISHERS_LIST: {
      const publishersList = action.payload;
      return {
        ...state,
        publishersList,
        shouldFetch: false,
      };
    }
    case SET_CURRENT_PUBLISHER: {
      const currentPublisher = action.payload;
      return {
        ...state,
        currentPublisher,
      };
    }
    case SET_PUBLISHERS_IS_LOADING: {
      const isLoading = action.payload;
      return {
        ...state,
        isLoading,
      };
    }
    case SET_SHOULD_FETCH: {
      const shouldFetch = action.payload;
      return {
        ...state,
        shouldFetch,
      };
    }
    default:
      return state;
  }
}
