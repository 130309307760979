import styled, { css } from 'styled-components';
import { Position } from './types';

export const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const TooltipTip = styled.div<{
  position?: Position;
  childHeight?: number;
}>`
  position: absolute;
  left: 50%;
  z-index: 100;
  width: max-content;
  max-width: 360px;
  padding: 8px;
  color: #fff;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0;
  word-wrap: break-word;
  background-color: #616d7c;
  border-radius: 4px;
  box-shadow: 0 4px 8px -2px rgba(16, 42, 67, 0.2);

  &:before {
    position: absolute;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: calc(6px * -1);
    border: solid transparent;
    border-width: 6px;
    content: ' ';
    pointer-events: none;
  }

  ${({ position, childHeight }) =>
    position === 'top' &&
    css`
      transform: translate(-50%, calc(-100% - ${childHeight}px - 10px));

      &:before {
        top: 100%;
        border-top-color: #616d7c;
      }
    `}

  ${({ position }) =>
    position === 'right' &&
    css`
      left: calc(100% + 10px);
      top: 50%;
      transform: translateX(0) translateY(-50%);

      &:before {
        left: calc(6px * -1);
        top: 50%;
        transform: translateX(0) translateY(-50%);
        border-right-color: #616d7c;
      }
    `}

  /** TODO: fix bottom tooltip positioning. */
  ${({ position, childHeight }) =>
    position === 'bottom' &&
    css`
      transform: translate(-50%, calc(-100% - ${childHeight}px - 10px;));

      &:before {
        top: -12px;
        border-bottom-color: #616d7c;
      }
    `}

  ${({ position }) =>
    position === 'left' &&
    css`
      left: auto;
      right: calc(100% + 10px);
      top: 50%;
      transform: translateX(0) translateY(-50%);

      &:before {
        left: auto;
        right: calc(6px * -2);
        top: 50%;
        transform: translateX(0) translateY(-50%);
        border-left-color: #616d7c;
      }
    `}
`;
