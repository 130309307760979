import styled from 'styled-components';
import { sharedStyles } from '../Input/styles';

export const InputField = styled.div`
  ${sharedStyles};
  width: 100%;
  padding: 8px 12px;
  cursor: text;
  transition: all 0.2s ease-in-out;
`;

export const InputTagField = styled.input`
  width: 100px;
  height: 26px;
  border: none;
  outline: none;
`;
