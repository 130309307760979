import React from 'react';
import { Spinner, StyledButton } from './styles';
import { IButton } from './types';

const Button: React.FC<IButton> = ({
  children,
  buttonType = 'default',
  shape = 'default',
  loading = false,
  onClick,
  value,
  ...rest
}) => {
  const handleOnClick = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ) => {
    if (loading) {
      e.preventDefault();
      return;
    }
    onClick?.(e);
  };

  return (
    <StyledButton
      buttonType={buttonType}
      shape={shape}
      value={value}
      isLoading={loading}
      onClick={handleOnClick}
      {...rest}
    >
      {loading && <Spinner dataTestid="spin" color={'#fffff'} />}
      {children}
    </StyledButton>
  );
};

export { Button as default };
