import styled, { css } from 'styled-components';

export const TagContainer = styled.span<{
  visible?: boolean;
  disabled?: boolean;
  closable?: boolean;
  background?: string;
}>`
  display: inline-block;
  box-sizing: border-box;
  min-height: 22px;
  margin: 0 4px 4px 0;
  padding: 3px 5px 2px;
  color: #616d7c;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0;
  white-space: nowrap;
  background-color: #eff1f3;
  border: 1px solid #cad2d9;
  border-radius: 4px;
  transition: all 0.2s;

  ${({ background }) =>
    background &&
    css`
      color: #ffffff;
      text-transform: uppercase;
      background-color: ${background};
    `}

  ${({ visible }) =>
    !visible &&
    css`
      display: none;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      color: #9aa4b0;
      border: 1px solid rgba(202, 210, 217, 0.3);
    `}

  ${({ closable }) =>
    closable &&
    css`
      &:hover {
        border-color: #47a3f3;
        box-shadow: 0 2px 8px -2px rgba(71, 163, 243, 0.5);
      }
    `}
`;

export const CloseIcon = styled.span`
  margin-left: 3px;
  line-height: 12px;
  vertical-align: middle;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    color: #47a3f3;
  }
`;
