import styled, { css } from 'styled-components';
import { CloseOutline } from '@easy-eva-icons/react';
import { motion } from 'framer-motion';
import { MessageType } from './types';

export const MessageContainer = styled(motion.div)<{ type: MessageType }>`
  display: inline-flex;
  flex-direction: row;
  min-width: 160px;
  max-width: 50%;
  margin-bottom: 12px;
  padding: 8px 14px 6px 11px;
  font-family: 'Roboto', sans-serif;
  background-color: #3e4b58;
  border: 2px solid #1e2833;
  border-radius: 4px;
  box-shadow: 0 2px 8px -2px rgba(62, 75, 88, 0.5);
  pointer-events: all;

  ${({ type }) =>
    type === 'info' &&
    css`
      border-color: #0967d2;
      background-color: #e6f6ff;
      box-shadow: 0 2px 8px -2px rgba(9, 103, 210, 0.3);
    `}

  ${({ type }) =>
    type === 'success' &&
    css`
      border-color: #16b796;
      background-color: #f0fcf9;
      box-shadow: 0 2px 8px -2px rgba(22, 183, 150, 0.3);
    `}

  ${({ type }) =>
    type === 'warning' &&
    css`
      border-color: #f0b427;
      background-color: #fefbea;
      box-shadow: 0 2px 8px -2px rgba(224, 45, 56, 0.3);
    `}

  ${({ type }) =>
    type === 'error' &&
    css`
      border-color: #e02d38;
      background-color: #ffe3e2;
      box-shadow: 0 2px 8px -2px rgba(224, 45, 56, 0.3);
      white-space: pre;
    `}
`;

export const MessageIcon = styled.span<{ type: MessageType }>`
  margin-right: 10px;
  color: #ffffff;

  svg {
    width: 16px;
    height: 16px;

    ${({ type }) =>
      type === 'info' &&
      css`
        color: #0967d2;
      `}

    ${({ type }) =>
      type === 'success' &&
      css`
        color: #16b796;
      `}

    ${({ type }) =>
      type === 'warning' &&
      css`
        color: #f0b427;
      `}

    ${({ type }) =>
      type === 'error' &&
      css`
        color: #e02d38;
      `}
  }
`;

export const MessageContent = styled.div<{ type: MessageType }>`
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  color: #fff;

  ${({ type }) =>
    type !== 'default' &&
    css`
      color: #616d7c;
    `}
`;

export const MessageTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.2px;
`;

export const MessageDescription = styled.div`
  margin: 8px 0 5px;
  font-size: 12px;
  letter-spacing: 0;
`;

export const CloseIcon = styled(CloseOutline)<{ type: MessageType }>`
  color: #fff;
  cursor: pointer;

  ${({ type }) =>
    type === 'info' &&
    css`
      color: #0967d2;
    `}

  ${({ type }) =>
    type === 'success' &&
    css`
      color: #16b796;
    `}

  ${({ type }) =>
    type === 'warning' &&
    css`
      color: #f0b427;
    `}

  ${({ type }) =>
    type === 'error' &&
    css`
      color: #e02d38;
    `}
`;

export const MessageClose = styled.div`
  width: 17px;
  height: 17px;
  margin-left: auto;
`;
