/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  UseFormRegister,
  FieldError,
  DeepMap,
  UseFormSetValue,
  UseFormGetValues,
} from 'react-hook-form';
import { Header } from 'common';
import { H1 } from 'common/Typography';
import { FormContent } from 'views/PublisherForm/styles';
import { Label } from 'components/Input/styles';
import {
  Libraries,
  LibrariesCheckbox,
  MarginCard,
  InputText,
  InputSuffix,
} from '../styles';

const ContainerConfiguration: React.FC<{
  register: UseFormRegister<any>;
  errors: DeepMap<any, FieldError>;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  librariesChecked: {
    prebid: boolean;
    gpt: boolean;
  };
}> = ({ register, errors, setValue, getValues, librariesChecked }) => {
  const setLibraryValue = (
    e: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    e.target.checked ? setValue(name, true) : setValue(name, false);
  };

  return (
    <>
      <Header>
        <H1>Container Configuration</H1>
      </Header>
      <MarginCard title="Basic configuration">
        <InputText
          label="Container's name"
          data-testid="container-name"
          {...register('name', {
            required: 'Container name is required.',
          })}
          errors={errors.name?.message}
        />
      </MarginCard>
      <MarginCard
        title="Bidding Configuration"
        error={errors.prebidTimeout || errors.failsafeTimeout}
      >
        <FormContent>
          <InputSuffix
            addonAfter="ms"
            label="Prebid Timeout"
            {...register('prebidTimeout')}
            type="number"
            errors={errors.prebidTimeout?.message}
            data-testid="prebidTimeout"
            defaultValue="1000"
          />
          <InputSuffix
            addonAfter="ms"
            type="number"
            label="Failsafe Timeout"
            {...register('failsafeTimeout')}
            errors={errors.failsafeTimeout?.message}
            data-testid="failsafeTimeout"
            defaultValue="4000"
          />
          <Libraries>
            <Label>Libraries</Label>
            <LibrariesCheckbox
              id="prebid"
              onChange={(e) => setLibraryValue(e, 'libraries.prebid')}
              checked={
                getValues('libraries.prebid') || librariesChecked?.prebid
              }
            >
              Include prebid.js in version 4.32.0
            </LibrariesCheckbox>
            <LibrariesCheckbox
              id="gpt"
              onChange={(e) => setLibraryValue(e, 'libraries.gpt')}
              checked={getValues('libraries.gpt') || librariesChecked?.gpt}
            >
              Include GPT.js
            </LibrariesCheckbox>
          </Libraries>
        </FormContent>
      </MarginCard>
    </>
  );
};

export default ContainerConfiguration;
