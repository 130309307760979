import apiClient from 'helpers/apiClient';
import { AxiosResponse } from 'axios';
import { apiUrl } from 'settings';
import { Publisher, CreateUpdatePublisherBody } from '@hbcc/api';

// TODO: temporary id extend
interface PublisherWithId extends CreateUpdatePublisherBody {
  id: number;
}

export const baseUrl = `${apiUrl}/publishers`;

const getAll = (): Promise<AxiosResponse<Publisher[]>> =>
  apiClient.get<Publisher[]>(baseUrl);

const getPublisher = (id: string): Promise<AxiosResponse<PublisherWithId>> =>
  apiClient.get<PublisherWithId>(`${baseUrl}/${id}`);

const createPublisher = (
  data: CreateUpdatePublisherBody
): Promise<AxiosResponse<Publisher>> =>
  apiClient.post<Publisher>(baseUrl, data);

const updatePublisher = (
  id: string,
  data: CreateUpdatePublisherBody
): Promise<AxiosResponse<Publisher>> =>
  apiClient.put<Publisher>(`${baseUrl}/${id}`, data);

const deletePublisher = (id: number): Promise<AxiosResponse<Publisher>> =>
  apiClient.delete(`${baseUrl}/${id}`);

export default {
  getAll,
  getPublisher,
  createPublisher,
  updatePublisher,
  deletePublisher,
};
