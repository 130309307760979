/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import ReactDOM from 'react-dom';
import Dialog from './Dialog';
import { IDialog } from './types';

export interface ModalFuncProps {
  visible?: boolean;
  title?: React.ReactNode;
  closable?: boolean;
  description?: React.ReactNode;
  // TODO: find out exact types
  onOk?: (...args: any[]) => void;
  onCancel?: (...args: any[]) => void;
  afterClose?: () => void;
  okText?: React.ReactNode;
  cancelText?: React.ReactNode;
}

type ConfigUpdate =
  | ModalFuncProps
  | ((prevConfig: ModalFuncProps) => ModalFuncProps);

const destroyFns: Array<() => void> = [];

export default function confirm(config = {} as ModalFuncProps): {
  destroy: () => void;
  update: (configUpdate: ConfigUpdate) => void;
} {
  const div = document.createElement('div');
  document.body.appendChild(div);
  let currentConfig = { ...config, close, visible: true } as any;

  function destroy(...args: any[]) {
    const unmountResult = ReactDOM.unmountComponentAtNode(div);
    if (unmountResult && div.parentNode) {
      div.parentNode.removeChild(div);
    }
    const triggerCancel = args.some((param) => param && param.triggerCancel);
    if (config.onCancel && triggerCancel) {
      config.onCancel(...args);
    }

    for (let i = 0; i < destroyFns.length; i += 1) {
      const fn = destroyFns[i];
      if (fn === close) {
        destroyFns.splice(i, 1);
        break;
      }
    }
  }

  function render({ ...props }: IDialog) {
    const { onOk, onCancel, ...rest } = props;
    setTimeout(() => {
      ReactDOM.render(
        <Dialog
          title="Delete element"
          description="Are you sure you want to delete this element?"
          okText="Delete"
          cancelText="Cancel"
          onOk={() => {
            close();
            if (onOk) {
              onOk();
            }
          }}
          onCancel={() => {
            close();
            if (onCancel) {
              onCancel();
            }
          }}
          {...rest}
        />,
        div
      );
    });
  }

  function close(...args: any[]) {
    currentConfig = {
      ...currentConfig,
      visible: false,
      afterClose: () => {
        if (typeof config.afterClose === 'function') {
          config.afterClose();
        }
        destroy.apply(args);
      },
    };
    render(currentConfig);
  }

  function update(configUpdate: ConfigUpdate) {
    if (typeof configUpdate === 'function') {
      currentConfig = configUpdate(currentConfig);
    } else {
      currentConfig = {
        ...currentConfig,
        ...configUpdate,
      };
    }
    render(currentConfig);
  }

  render(currentConfig);
  destroyFns.push(close);

  return {
    destroy: close,
    update,
  };
}
