import styled, { css } from 'styled-components';
import { sharedStyles } from 'components/Input/styles';

// eslint-disable-next-line import/prefer-default-export
export const TextAreaField = styled.textarea<{ errors?: boolean }>`
  ${sharedStyles};
  min-height: 40px;
  padding: 10px 16px;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;

  :disabled {
    resize: none;
  }

  &:hover:not(:disabled) {
    ${({ errors }) =>
      !errors &&
      css`
        border-color: #47a3f3;
      `}
  }

  ${({ errors }) =>
    errors &&
    css`
      border-color: #f86a6a;
      background-color: #fff1f0;
    `}
`;
