/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  UseFormRegister,
  FieldError,
  DeepMap,
  UseFormSetValue,
} from 'react-hook-form';
import { Bidder, BiddersConfig } from '@hbcc/api';
import BiddersConfiguration from 'common/BiddersConfiguration';
import { parseForm } from 'common/Form/utils';
import { H1 } from 'common/Typography';
import { Header } from 'common';
import { MarginCard, BasicConfiguration, InputText } from '../styles';
import TagSelect from '../../../components/TagSelect/TagSelect';

const AdUnit: React.FC<{
  register: UseFormRegister<any>;
  errors: DeepMap<any, FieldError>;
  setValue: UseFormSetValue<any>;
  adUnit: {
    adUnit: any;
    index: number;
  };
  bidders: Bidder[];
  isSubmitting: boolean;
  isValid: boolean;
  publisherBidders?: BiddersConfig[];
}> = ({
  register,
  errors,
  setValue,
  adUnit,
  bidders,
  isSubmitting,
  isValid,
  publisherBidders,
}) => {
  const getBidders = (): BiddersConfig[] | [] => {
    if (!adUnit.adUnit) {
      return [];
    }
    const { code, sizes, ...rest } = adUnit.adUnit;
    const filteredBidders = Object.fromEntries(
      Object.entries(rest).filter(([, value]) => value !== null)
    );
    const biddersConfigurations = parseForm(bidders, filteredBidders);

    if (
      biddersConfigurations.length === 0 &&
      publisherBidders &&
      publisherBidders.length > 0
    ) {
      publisherBidders.forEach((bidder) => {
        Object.entries(bidder.params).forEach(([key, value]) => {
          setValue(`adUnits.${adUnit.index}.${bidder.bidder}.${key}`, value);
        });
      });
      return publisherBidders;
    }
    return biddersConfigurations;
  };

  const bidderConf = getBidders();

  const validateSizeFormat = (v: React.ReactNode | string) => {
    const value = (v as string).split('x');

    return (
      value.length === 2 &&
      !Number.isNaN(parseInt(value[0], 10)) &&
      !Number.isNaN(parseInt(value[1], 10))
    );
  };

  return (
    <>
      <div key={adUnit.index}>
        <Header>
          <H1>{adUnit.adUnit.code || 'New Ad Unit'}</H1>
        </Header>
        <MarginCard closable title="Basic Configuration">
          <BasicConfiguration>
            <InputText
              label="Ad Unit Code"
              {...register(`adUnits.${adUnit.index}.code`, {
                required: 'Ad Unit Code is required.',
              })}
              errors={errors.adUnits?.[adUnit.index]?.code?.message}
              data-testid={`adUnits-${adUnit.index}-code`}
            />
            <TagSelect
              label="Sizes"
              id="sizes"
              onChange={(values) => {
                setValue(
                  `adUnits.${adUnit.index}.sizes`,
                  values.map((t) => t.replace('x', ','))
                );
              }}
              validateValue={validateSizeFormat}
              defaultValues={adUnit.adUnit?.sizes.map((t: string) =>
                t.replace(',', 'x')
              )}
            />
          </BasicConfiguration>
        </MarginCard>
        <BiddersConfiguration
          register={register}
          errors={errors}
          bidders={bidders}
          biddersConfig={bidderConf}
          index={adUnit.index}
          setValue={setValue}
          isSubmitting={isSubmitting}
          isValid={isValid}
        />
      </div>
    </>
  );
};

export default AdUnit;
