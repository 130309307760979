import React from 'react';
import ReactDOM from 'react-dom';
import { IPortal } from './types';

const portalId = 'react-modal-portal';

const isBrowser =
  typeof window !== 'undefined' && typeof window.document !== 'undefined';

const Portal: React.FC<IPortal> = ({ children }) => {
  let portalElement;
  const existingPortal = document.getElementById(portalId);

  const getPortalContainer = (): HTMLElement => {
    if (existingPortal) {
      portalElement = existingPortal;
    } else {
      const el = document.createElement('div');
      el.id = portalId;

      if (document.body != null) {
        document.body.appendChild(el);
      }
      portalElement = el;
    }
    return portalElement;
  };

  return isBrowser
    ? ReactDOM.createPortal(children, getPortalContainer())
    : null;
};

export default Portal;
