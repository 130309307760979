/* stylelint-disable selector-type-no-unknown, rule-empty-line-before, no-descending-specificity */
import styled, { css } from 'styled-components';

export const Loading = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 14px;
  pointer-events: all;
`;

export const TableWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const Table = styled.table<{ isLoading?: boolean }>`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;

  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0.4;
    `}
`;

export const TBody = styled.tbody`
  color: #102a43;
  font-size: 14px;
`;

export const THead = styled.thead`
  color: #9aa4b0;
  font-size: 12px;
`;

export const THeadCell = styled.th`
  padding: 10px 24px;
  color: #9aa4b0;
  font-weight: 500;
  letter-spacing: 1.13px;
  text-align: left;
  text-transform: uppercase;
  overflow-wrap: break-word;
`;

export const THeadRow = styled.tr``;

export const TBodyCell = styled.td`
  padding: 0 24px;
  vertical-align: middle;
  overflow-wrap: break-word;
`;

export const TBodyRow = styled.tr<{ empty?: boolean }>`
  height: 40px;
  background-color: #fff;
  border: 1px solid rgba(202, 210, 217, 0.3);
  border-radius: 2px;

  ${({ empty }) =>
    empty &&
    css`
      height: 280px;
      background-color: transparent;
    `}

  ${({ empty }) =>
    !empty &&
    css`
      :hover {
        position: relative;
        border-radius: 1px;
        box-shadow: 0 6px 16px 0 rgba(217, 226, 236, 0.3);

        ${TBodyCell}:first-child {
          border-left: 4px solid #0967d2;
        }
      }
    `}

  ${TBodyCell}:first-child {
    padding-left: 22px;
    border-left: 4px solid transparent;
  }
`;

export const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  button:not(:last-child) {
    margin-right: 8px;
  }
`;

export const PaginationButton = styled.button<{ active?: boolean }>`
  min-width: 37px;
  height: 37px;
  color: #9aa4b0;
  font-size: 14px;
  background: transparent;
  border: 1px solid #cad2d9;
  border-radius: 5px;
  cursor: pointer;

  :hover {
    color: #616d7c;
    border-color: #9aa4b0;
  }

  :disabled {
    cursor: not-allowed;
  }

  ${({ active }) =>
    active &&
    css`
      color: #616d7c;
      border-color: #9aa4b0;
    `}
`;

export const Ellipsis = styled.span`
  width: 37px;
  height: 37px;
  margin-right: 8px;
  color: #9aa4b0;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  vertical-align: middle;
`;
