/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect } from 'react';
import { CloseOutline } from '@easy-eva-icons/react';
import { TagContainer, CloseIcon } from './styles';
import { ITag } from './types';

const Tag: React.FC<ITag> = ({
  children,
  closable = false,
  closeIcon,
  visible = true,
  disabled = false,
  onClose,
  background,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if ('visible' in props) {
      setIsVisible(visible);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const handleClose = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onClose?.(e, children);

    if (e.defaultPrevented) {
      return;
    }
    if (!('visible' in props)) {
      setIsVisible(false);
    }
  };

  const renderCloseIcon = () => {
    if (closable && !disabled) {
      return (
        <CloseIcon data-testid="tag-close" onClick={handleClose}>
          {closeIcon || <CloseOutline />}
        </CloseIcon>
      );
    }

    return null;
  };

  return (
    <TagContainer
      visible={isVisible}
      disabled={disabled}
      closable={closable}
      background={background}
      {...props}
    >
      {children}
      {renderCloseIcon()}
    </TagContainer>
  );
};

export default Tag;
