import Spin from 'components/Spin';
import styled, { css } from 'styled-components';
import { ButtonShape, ButtonTypes } from './types';

export const StyledButton = styled.button<{
  buttonType?: ButtonTypes;
  shape?: ButtonShape;
  isLoading?: boolean;
}>`
  display: inline-block;
  box-sizing: border-box;
  height: 40px;
  padding: ${({ isLoading }) =>
    isLoading ? '10px 28px 10px 24px' : '10px 30px'};
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  text-decoration: none;
  border-style: solid;
  border-width: 2px;
  border-radius: 4px;
  outline: none;
  cursor: ${({ isLoading }) => (isLoading ? 'auto' : 'pointer')};
  transition: all 0.1s;

  &:disabled {
    cursor: not-allowed;
  }

  ${({ buttonType }) =>
    buttonType === 'default' &&
    css`
      background-color: transparent;
      color: #616d7c;
      border-color: #9aa4b0;

      &:hover {
        background-color: #edf6fc;
        border-color: #3fa1ef;
        color: #3fa1ef;
        box-shadow: 0 2px 8px -2px rgba(9, 103, 210, 0.2);
      }

      &:active {
        background-color: #e5f6fe;
        box-shadow: 0 2px 8px -2px rgba(9, 103, 210, 0.4);
      }

      &:disabled {
        color: #cad2d9;
        background-color: #f5f7f9;
        border-color: #dfe4e9;
        box-shadow: none;
      }
    `}

  ${({ buttonType }) =>
    buttonType === 'primary' &&
    css`
      color: #fff;
      border-color: #c1162c;
      border-radius: 4px;
      background: linear-gradient(180deg, #e42c3d 0%, #cb1d31 100%),
        rgb(224, 45, 56);
      box-shadow: 0 2px 8px -2px rgba(224, 45, 56, 0.4);

      &:hover {
        border-color: #c81a30;
        background: linear-gradient(180deg, #e22a3c 0%, #b20b24 100%),
          rgb(185, 15, 40);
      }

      &:active {
        border-color: #af0923;
        background: linear-gradient(180deg, #c7192f 0%, #b00923 100%),
          rgba(178, 11, 37, 255);
      }

      &:disabled {
        color: #cad2d9;
        background: #f5f7f9;
        border-color: #dfe4e9;
        box-shadow: none;
      }
    `}

  ${({ buttonType }) =>
    buttonType === 'transparent' &&
    css`
      background-color: transparent;
      border-width: 0;
    `}

  ${({ shape }) =>
    shape === 'circle' &&
    css`
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      padding: 0;
      color: #9aa4b0;
      border-color: #9aa4b0;

      &.active,
      &:hover {
        background-color: #e6f6ff;
        box-shadow: 0 2px 8px -2px rgba(71, 163, 243, 0.5);
        color: #47a3f3;
      }
    `}

  ${({ shape }) =>
    shape === 'square' &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      padding: 0;

      &.active,
      &:hover {
        background-color: #e6f6ff;
        svg {
          fill: #47a3f3;
        }
      }
    `}

  ${({ isLoading }) =>
    isLoading &&
    css`
      border: 2px solid rgba(170, 10, 29, 0.5);
      opacity: 0.5;
      background-color: #e02d38;
      background: linear-gradient(180deg, #e02d38 0%, #aa0a1d 100%);
      box-shadow: 0 2px 8px -2px rgba(224, 45, 56, 0.4);
    `}
`;

export const Spinner = styled(Spin)`
  width: 15px;
  height: 15px;
  margin-right: 8px;
`;

export default StyledButton;
