import styled, { css } from 'styled-components';
import { InboxOutline } from '@easy-eva-icons/react';

export const EmptyContainer = styled.div<{ padded?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  ${({ padded }) =>
    padded &&
    css`
      margin: 36px 0;
    `}
`;

export const EmptyIcon = styled(InboxOutline)`
  width: 48px;
  height: 48px;
  color: #cad2d9;
`;

export const EmptyDescription = styled.div`
  color: #cad2d9;
  font-size: 14px;
`;

export const EmptyChildren = styled.div`
  margin-top: 10px;
`;
