import React, { useEffect, useState, useContext } from 'react';
import {
  Sidebar,
  NavContainer,
  TabItem,
  AdUnitItem,
} from 'common/Sidebar/styles';
import { useHistory, useParams, generatePath } from 'react-router-dom';
import message from 'components/Message';
import biddersService from 'services/Bidders/bidders.service';
import { routes } from 'settings';
import { Bidder, BiddersConfig, LibrariesIncludes } from '@hbcc/api';
import { SubmitHandler, useForm } from 'react-hook-form';
import { PublisherContext } from 'contexts/PublisherContext/PublisherContext';
import containerService from 'services/Container/container.service';
import publisherService from 'services/Publisher/publisher.service';
import OverlayModal from 'components/OverlayModal';
import {
  Container as Wrapper,
  FormContainer,
  Separator,
  AddAdUnitButton,
  PlusIcon,
  TrashIcon,
} from './styles';
import ContainerConfiguration from './tabs/ContainerConfiguration';
import PriceBucketGranularity from './tabs/PriceBucketGranularity';
import AdUnit from './tabs/AdUnit';
import ContainerTitleBar from './ContainerTitleBar';
import {
  areValidAdUnits,
  getAdUnit,
  getAdUnitsWithParsedBidders,
  tabs,
} from './utils';

const Container: React.FC = () => {
  const history = useHistory();
  const { containerId } = useParams<{
    containerId?: string;
  }>();
  const [allBidders, setAllBidders] = useState<Bidder[]>([]);
  const [biddersConfig, setBiddersConfig] = useState<BiddersConfig[]>([]);
  const { state } = useContext(PublisherContext);
  const { currentPublisher } = state;
  const [librariesChecked, setLibrariesChecked] = useState<LibrariesIncludes>({
    prebid: false,
    gpt: false,
  });
  const [activeTab, setActiveTab] = useState('container-configuration');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [adUnitsValues, setAdUnitsValues] = useState<any[]>([]);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    trigger,
    setValue,
    getValues,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  } = useForm<any>({
    defaultValues: {
      adUnits: [
        {
          code: '',
          sizes: [],
        },
      ],
    },
  });

  useEffect(() => {
    biddersService
      .getAll()
      .then(({ data }) => {
        setAllBidders(data);
      })
      .catch(() => message.error('Error occured while fetching bidders list.'));
  }, []);

  useEffect(() => {
    Object.entries(librariesChecked).forEach(([key, value]) =>
      setValue(`libraries.${key}`, value)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [librariesChecked]);

  useEffect(() => {
    setValue('adUnits', adUnitsValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adUnitsValues]);

  useEffect(() => {
    if (currentPublisher.name !== '') {
      if (containerId) {
        containerService
          .getContainer(currentPublisher.id, containerId)
          .then(({ data }) => {
            const { bidConfig, priceBucketGranularities, adUnits, ...rest } =
              data;
            adUnits.forEach((adUnit, index) => {
              const { code, sizes, bidders } = adUnit;
              const transformedSizes = sizes.map((size) => size.join());
              setValue(`adUnits.${index}.code`, code);
              setValue(`adUnits.${index}.sizes`, transformedSizes);
              bidders.forEach((bidder) => {
                Object.entries(bidder.params).forEach(([key, value]) => {
                  setValue(`adUnits.${index}.${bidder.bidder}.${key}`, value);
                });
              });
            });

            Object.entries(bidConfig).forEach(([key, value]) =>
              key !== 'libraries'
                ? setValue(key, value)
                : setLibrariesChecked(value)
            );
            Object.entries(priceBucketGranularities[0]).forEach(
              ([key, value]) => setValue(key, value)
            );
            Object.entries(rest).forEach(([key, value]) =>
              setValue(key, value)
            );
            setAdUnitsValues(getValues('adUnits'));
          })
          .catch(() =>
            message.error('Error occured while fetching container.')
          );
      }
      publisherService
        .getPublisher(String(currentPublisher.id))
        .then(({ data }) => setBiddersConfig(data.biddersConfiguration));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerId, currentPublisher.name]);

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const onSubmit: SubmitHandler<any> = async (data) => {
    const {
      name,
      adUnits,
      libraries: { gpt, prebid },
      precision,
      from,
      to,
      increment,
      prebidTimeout,
      failsafeTimeout,
    } = data;

    if (adUnits.length === 0) {
      return message.error('At least one Ad Unit needs to be added.', {
        duration: 5,
      });
    }
    if (!areValidAdUnits(adUnits)) {
      return message.error('All fields in added Ad Units are required.', {
        duration: 5,
      });
    }
    if (
      from === undefined ||
      Object.entries([precision, from, to, increment]).some(
        ([, value]) => value === ''
      )
    ) {
      return message.error(
        'All fields in Price Bucket Granularity are required.',
        { duration: 5 }
      );
    }

    const validation = await trigger();

    const newContainer = {
      name,
      status: 'published',
      adUnits: getAdUnitsWithParsedBidders(adUnits, allBidders),
      priceBucketGranularities: [
        {
          precision: Number(precision),
          from: Number(from),
          to: Number(to),
          increment: Number(increment),
        },
      ],
      bidConfig: {
        prebidTimeout: Number(prebidTimeout),
        failsafeTimeout: Number(failsafeTimeout),
        libraries: {
          gpt: gpt || false,
          prebid: prebid || false,
        },
      },
    };

    const setupData = {
      publisherId: currentPublisher.id,
      containerId,
      adUnits: newContainer.adUnits,
      libraries: newContainer.bidConfig.libraries,
    };

    if (validation) {
      if (containerId) {
        return containerService
          .updateContainer(currentPublisher.id, containerId, newContainer)
          .then(() => {
            message.success('Container updated successfully.');
            history.push(
              generatePath(routes.setupInstructions, {
                containerId,
              }),
              {
                setupData,
              }
            );
          })
          .catch(() =>
            message.error('Error occured while updating container.')
          );
      }
      return containerService
        .createContainer(currentPublisher.id, newContainer)
        .then((response) => {
          const newContainerId = response.data.containerId;
          message.success('New container created successfully.');
          history.push(
            generatePath(routes.setupInstructions, {
              containerId: newContainerId,
            }),
            {
              setupData: { ...setupData, containerId: newContainerId },
            }
          );
        })
        .catch(() =>
          message.error('Error occured while creating new container.')
        );
    }
    return message.error('Check if all required fields are filled.', {
      duration: 5,
    });
  };

  const addAdUnitField = () => {
    setAdUnitsValues((prevState) => [...prevState, { code: '', sizes: [] }]);
  };

  const removeAdUnitFields = (index: number) => {
    const newAdunitsValues = [...adUnitsValues];
    newAdunitsValues.splice(index, 1);
    setAdUnitsValues(newAdunitsValues);
    setActiveTab('container-configuration');
  };

  return (
    <OverlayModal title={<ContainerTitleBar isContainerId={!!containerId} />}>
      <Wrapper>
        <Sidebar>
          <NavContainer>
            {tabs.map((view) => (
              <TabItem
                key={view.key}
                isActive={activeTab === view.key}
                onClick={() => {
                  if (activeTab !== view.key) {
                    setActiveTab(view.key);
                  }
                }}
              >
                {view.tab}
              </TabItem>
            ))}
            <Separator />
            <AddAdUnitButton
              buttonType="transparent"
              onClick={async () => {
                addAdUnitField();
              }}
              data-testid="add-ad-unit"
            >
              <PlusIcon />
              Add Ad Unit
            </AddAdUnitButton>
            {adUnitsValues.map((adUnit, index) => (
              <AdUnitItem
                key={`${adUnit.code}-${index}`}
                isActive={activeTab === `ad-unit-${index}`}
                onClick={() => {
                  if (activeTab !== `ad-unit-${index}`) {
                    setActiveTab(`ad-unit-${index}`);
                  }
                }}
              >
                {adUnit.code || 'New Ad Unit'}
                {activeTab === `ad-unit-${index}` && (
                  <TrashIcon
                    onClick={(): void => removeAdUnitFields(index)}
                    data-testid="remove-ad-unit-icon"
                  />
                )}
              </AdUnitItem>
            ))}
          </NavContainer>
        </Sidebar>
        <FormContainer>
          <form id="container-form" onSubmit={handleSubmit(onSubmit)}>
            {activeTab === 'container-configuration' && (
              <ContainerConfiguration
                register={register}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                librariesChecked={librariesChecked}
              />
            )}
            {activeTab === 'price-bucket-granularity' && (
              <PriceBucketGranularity register={register} errors={errors} />
            )}
            {activeTab.startsWith('ad-unit') && (
              <AdUnit
                register={register}
                errors={errors}
                setValue={setValue}
                adUnit={getAdUnit(activeTab, adUnitsValues)}
                publisherBidders={biddersConfig}
                bidders={allBidders}
                isSubmitting={isSubmitting}
                isValid={isValid}
              />
            )}
          </form>
        </FormContainer>
      </Wrapper>
    </OverlayModal>
  );
};
export default Container;
