import React from 'react';
import ReactDOM from 'react-dom';
import { Router, matchPath } from 'react-router-dom';
import history from 'routerHistory';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { QueryClient, QueryClientProvider } from 'react-query';
import { routes as views } from 'settings';
import UserContextProvider from 'contexts/UserContext';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

if (
  process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_SENTRY_ENVIRONMENT !== 'local' &&
  process.env.REACT_APP_SENTRY_DSN
) {
  // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
  const routes = Object.keys(views).map((view) => ({ path: views[view] }));

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    release: `ui@${process.env.REACT_APP_VERSION}`,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(
          history,
          routes,
          matchPath
        ),
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Router history={history}>
      <UserContextProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </UserContextProvider>
    </Router>
  </QueryClientProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
