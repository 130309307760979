import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from 'contexts/UserContext';
import { Redirect } from 'react-router-dom';
import { routes } from 'settings';

const LoggedIn: React.FC = ({ children }) => {
  const {
    state: { isAuth },
    dispatch,
  } = useContext(UserContext);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (isAuth) {
      setLoggedIn(true);
    }
  }, [isAuth, dispatch]);

  return isAuth ? <>{loggedIn && children}</> : <Redirect to={routes.signIn} />;
};

export default LoggedIn;
