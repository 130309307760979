import React, { forwardRef } from 'react';
import { Label as TopLabel } from 'components/Input/styles';
import {
  SwitchContainer,
  SwitchLabel,
  Checkbox,
  Slider,
  Label,
} from './styles';
import { ISwitch } from './types';

const Switch: React.FC<ISwitch> = forwardRef<HTMLInputElement, ISwitch>(
  function Switch({ label, topLabel, defaultChecked, disabled, ...rest }, ref) {
    return (
      <SwitchContainer data-testid="switch" topLabel={!!topLabel}>
        {topLabel && <TopLabel>{topLabel}</TopLabel>}
        <SwitchLabel>
          <Checkbox
            ref={ref}
            type="checkbox"
            disabled={disabled}
            defaultChecked={defaultChecked}
            {...rest}
          />
          <Slider disabled={disabled} />
        </SwitchLabel>
        {label && !topLabel && <Label>{label}</Label>}
      </SwitchContainer>
    );
  }
);

export default Switch;
