import styled, { css } from 'styled-components';

export const Icon = styled.svg`
  fill: none;
  stroke: transparent;
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
`;

export const Checkmark = styled.span<{ checked: boolean }>`
  width: 16px;
  height: 16px;
  margin: 0 8px 0 6px;
  border: 1px solid #cad2d9;
  border-radius: 4px;
  transition: all 150ms;

  ${Icon} {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};

    ${({ checked }) =>
      checked &&
      css`
        fill: none;
        stroke: #0967d2;
        stroke-width: 2px;
      `}
  }
`;

export const Label = styled.label`
  display: inline-flex;
  align-items: center;
  color: #616d7c;
  font-size: 14px;
  letter-spacing: 0;
  cursor: pointer;
  user-select: none;
`;
