import React from 'react';
import styled from 'styled-components';

const Heading = styled.span`
  display: flex;
  flex-direction: column;
`;

const Subtitle = styled.div`
  color: #9aa4b0;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1.13px;
`;

interface IHeading {
  subtitle?: string;
}

const Heading1 = styled.h1`
  margin: 0;
`;

// eslint-disable-next-line import/prefer-default-export
export const H1: React.FC<IHeading> = ({ children, subtitle }) => (
  <Heading>
    <Heading1>{children}</Heading1>
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
  </Heading>
);
