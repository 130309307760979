import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const SidebarWidth = '250px';

const Sidebar = styled.div`
  position: fixed;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${SidebarWidth};
  height: 100%;
  min-height: 100%;
  background: #fff;
  box-shadow: 6px 6px 16px 0 rgba(217, 226, 236, 0.25);
`;

const NavContainer = styled.nav`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const commonItemStyles = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 57px;
  min-height: 57px;
  padding-right: 24px;
  padding-left: 32px;
  color: #1e2833;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none;
  cursor: pointer;

  &.active {
    padding-left: 28px;
    background-color: #e6f6ff;
    border-left: 4px solid #0967d2;
  }
`;

const NavItem = styled(NavLink)`
  ${commonItemStyles}
`;

const AdUnitItem = styled.button<{ isActive: boolean }>`
  ${commonItemStyles}
  width: 100%;
  background-color: #ffffff;
  border: none;
  outline: none;

  ${({ isActive }) =>
    isActive &&
    css`
      padding-left: 28px;
      background-color: #e6f6ff;
      border-left: 4px solid #0967d2;
    `}
`;

const TabItem = styled(AdUnitItem)`
  font-size: 16px;
`;

export { Sidebar, NavContainer, NavItem, AdUnitItem, TabItem };
