import React, { useEffect, useRef } from 'react';
import { AnimatePresence } from 'framer-motion';

import Button from 'components/Button';
import { Actions, Container, Content, Description, Mask, Title } from './style';
import { IDialog } from './types';

const Dialog: React.FC<IDialog> = ({
  title,
  description,
  onCancel,
  onOk,
  visible,
  customActions,
  okText = 'Proceed',
  cancelText = 'Cancel',
  afterClose,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        onCancel
      ) {
        onCancel();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onCancel]);

  useEffect(() => {
    if (!visible && afterClose) {
      afterClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <AnimatePresence>
      {visible ? (
        <>
          <Mask>
            <Container
              className="modal"
              id="modal"
              ref={ref}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0 }}
            >
              <Content>
                <Title>{title}</Title>
                <Description>{description}</Description>
              </Content>
              <Actions>
                {customActions || (
                  <>
                    <Button onClick={onCancel}>{cancelText}</Button>
                    <Button buttonType="primary" onClick={onOk}>
                      {okText}
                    </Button>
                  </>
                )}
              </Actions>
            </Container>
          </Mask>
        </>
      ) : null}
    </AnimatePresence>
  );
};

export default Dialog;
