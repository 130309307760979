import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
`;

export const Label = styled.span<{ color?: string }>`
  margin-top: 10px;
  color: ${({ color }) => color || '#cad2d9'};
`;

export const Indicator = styled.span<{ color?: string }>`
  display: inline-block;
  width: 24px;
  height: 24px;
  border-color: ${({ color }) => color || '#cad2d9'};
  border-style: solid;
  border-width: 2px;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;
