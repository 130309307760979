import React from 'react';
import Button from 'components/Button';
import { TitleBar } from './styles';

const ContainerTitleBar: React.FC<{ isContainerId: boolean }> = ({
  isContainerId,
}) => (
  <TitleBar>
    <div>{`Container ${isContainerId ? 'Editor' : 'Creator'}`}</div>
    <Button
      type="submit"
      form="container-form"
      buttonType="primary"
      data-testid="submit"
    >
      {`${isContainerId ? 'Update' : 'Create'} Container`}
    </Button>
  </TitleBar>
);

export default ContainerTitleBar;
