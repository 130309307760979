import styled from 'styled-components';
import {
  Trash2Outline,
  EditOutline,
  QuestionMarkCircleOutline,
  FileTextOutline,
} from '@easy-eva-icons/react';

import { itemIcon } from 'components/Dropdown/styles';
import { ITag } from 'components/Tag/types';
import { TagContainer } from 'components/Tag/styles';

export const TrashIcon = styled(Trash2Outline)`
  ${itemIcon}
`;

export const EditIcon = styled(EditOutline)`
  ${itemIcon}
`;

export const SetupInstructionsIcon = styled(FileTextOutline)`
  ${itemIcon}
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const QuestionMarkIcon = styled(QuestionMarkCircleOutline)`
  width: 14px;
  height: 14px;
  margin-left: 5px;
  fill: #cad2d9;
`;

export const BiddersContainer = styled.span`
  display: flex;
`;

const colorVariants = {
  yellow: '#F0B427',
  blue: '#0967D2',
  default: '#eff1f3',
};

function getTagColor({ variant = 'default' }: ITag): string {
  return colorVariants[variant];
}

export const FilledTag = styled(TagContainer)<ITag>`
  display: inline-flex;
  align-items: center;
  color: #fff;
  text-transform: uppercase;
  background-color: ${(props) => getTagColor(props)};
  border: none;
`;
