/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { UseFormRegister, FieldError, DeepMap } from 'react-hook-form';
import Input from 'components/Input';
import { Header } from 'common';
import { H1 } from 'common/Typography';
import { MarginCard, PriceBucketContainer } from '../styles';

const PriceBucketGranularity: React.FC<{
  register: UseFormRegister<any>;
  errors: DeepMap<any, FieldError>;
}> = ({ register, errors }) => (
  <>
    <Header>
      <H1>Price Bucket Granularity</H1>
    </Header>
    <MarginCard
      title="Price Bucket Granularity"
      error={errors.precision || errors.from || errors.to || errors.increment}
    >
      <PriceBucketContainer>
        <Input
          type="number"
          step="0.01"
          addonBefore="$"
          label="Precision"
          {...register('precision', {
            required: 'Precision is required.',
          })}
          errors={errors.precision?.message}
          data-testid="precision"
        />
        <Input
          type="number"
          step="0.01"
          addonBefore="$"
          label="From"
          {...register('from', {
            required: 'From is required.',
          })}
          errors={errors.from?.message}
          data-testid="from"
        />
        <Input
          type="number"
          step="0.01"
          addonBefore="$"
          label="To"
          {...register('to', {
            required: 'To is required.',
          })}
          errors={errors.to?.message}
          data-testid="to"
        />
        <Input
          addonBefore="$"
          label="Increment"
          {...register('increment', {
            required: 'Increment is required.',
          })}
          errors={errors.increment?.message}
          type="number"
          step="0.01"
          data-testid="increment"
        />
      </PriceBucketContainer>
    </MarginCard>
  </>
);

export default PriceBucketGranularity;
