import React, { useEffect, useState, useContext } from 'react';
import { Header, Container, Footer } from 'common';
import { H1 } from 'common/Typography';
import message from 'components/Message';
import Button from 'components/Button';
import Card from 'components/Card';
import Input from 'components/Input';
import biddersService from 'services/Bidders/bidders.service';
import publisherService from 'services/Publisher/publisher.service';
import { routes } from 'settings';
import { useHistory, useParams } from 'react-router-dom';
import { Bidder, BiddersConfig } from '@hbcc/api';
import { useForm, SubmitHandler } from 'react-hook-form';
import BiddersConfiguration from 'common/BiddersConfiguration';
import { setShouldFetch } from 'contexts/PublisherContext/Actions';
import { PublisherContext } from 'contexts/PublisherContext/PublisherContext';
import { FormContent } from 'common/Form/styles';
import { parseForm } from 'common/Form/utils';
import { ActionsDivider } from './styles';

const Publisher: React.FC = () => {
  const history = useHistory();
  const { publisherId } = useParams<{ publisherId?: string }>();
  const [bidders, setBidders] = useState<Bidder[]>([]);
  const [biddersConfig, setBiddersConfig] = useState<BiddersConfig[]>([]);
  const { dispatch } = useContext(PublisherContext);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isSubmitting, isValid },
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  } = useForm<any>();

  useEffect(() => {
    biddersService
      .getAll()
      .then(({ data }) => {
        setBidders(data);
      })
      .catch(() => message.error('Error occured while fetching bidders list.'));
  }, []);

  useEffect(() => {
    if (publisherId) {
      publisherService
        .getPublisher(publisherId)
        .then(({ data }) => {
          const { biddersConfiguration, id, ...rest } = data;
          setBiddersConfig(biddersConfiguration);
          // Set main form fields (name, description, gamNetworkId)
          Object.entries(rest).forEach(([key, value]) => setValue(key, value));
          // );
        })
        .catch(() => message.error('Error occured while fetching publisher.'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publisherId]);

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const onSubmit: SubmitHandler<any> = async (data) => {
    const { name, description, gamNetworkId, ...rest } = data;
    const filteredBidders = Object.fromEntries(
      Object.entries(rest).filter(([, value]) => value !== null)
    );
    const biddersConfiguration = parseForm(bidders, filteredBidders);
    const newPublisher = {
      name,
      description,
      gamNetworkId: Number.parseInt(gamNetworkId, 10),
      biddersConfiguration,
    };
    if (publisherId) {
      return publisherService
        .updatePublisher(publisherId, newPublisher)
        .then(() => {
          message.success('Publisher updated successfully.');
          history.push(routes.publishers);
        })
        .catch(() => message.error('Error occured while updating publisher.'))
        .finally(() => dispatch(setShouldFetch(true)));
    }
    return publisherService
      .createPublisher(newPublisher)
      .then(() => {
        message.success('New publisher created successfully.');
        history.push(routes.publishers);
      })
      .catch(() => message.error('Error occured while creating new publisher.'))
      .finally(() => dispatch(setShouldFetch(true)));
  };

  return (
    <Container>
      <Header spaced>
        <H1>{`${publisherId ? 'Edit' : 'Add'} Publisher`}</H1>
      </Header>
      <form id="publisher-form" onSubmit={handleSubmit(onSubmit)}>
        <Card closable title="Basic configuration">
          <FormContent>
            <Input
              label="Publishers name"
              data-testid="publisher-name"
              {...register('name', {
                required: 'Publisher name is required.',
              })}
              errors={errors.name?.message}
            />
            <Input
              label="Description"
              data-testid="publisher-description"
              {...register('description', {
                required: 'Description is required.',
              })}
              errors={errors.description?.message}
            />
            <Input
              label="GAM Network ID"
              data-testid="gam-id"
              {...register('gamNetworkId', {
                required: 'GAM Network ID is required.',
              })}
              errors={errors.gamNetworkId?.message}
            />
          </FormContent>
        </Card>
        <BiddersConfiguration
          register={register}
          errors={errors}
          bidders={bidders}
          biddersConfig={biddersConfig}
          setValue={setValue}
          isSubmitting={isSubmitting}
          isValid={isValid}
        />
      </form>
      <ActionsDivider />
      <Footer>
        <Button
          data-testid="cancel"
          onClick={() => history.push(routes.publishers)}
        >
          Cancel
        </Button>
        <Button
          data-testid="submit"
          loading={isSubmitting}
          type="submit"
          disabled={!isDirty}
          form="publisher-form"
          buttonType="primary"
        >
          {`${publisherId ? 'Update' : 'Add'} Publisher`}
        </Button>
      </Footer>
    </Container>
  );
};
export default Publisher;
