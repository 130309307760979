import styled, { css } from 'styled-components';
import {
  ChevronDownOutline,
  CloseCircle,
  SearchOutline,
} from '@easy-eva-icons/react';
import Checkbox from 'components/Checkbox';
import {
  ItemsContainer as Container,
  ItemsList as List,
} from 'components/Dropdown/styles';
import Spin from 'components/Spin';

const iconStyle = css`
  position: absolute;
  color: #9aa4b0;
`;

export const SelectContainer = styled.div`
  position: relative;
  margin: 16px 0;
`;

export const Chevron = styled(ChevronDownOutline)`
  ${iconStyle}
  right: 12px;
  width: 22px;
  height: 22px;
  pointer-events: none;
`;

export const Clear = styled(CloseCircle)`
  ${iconStyle}
  right: 15px;
  width: 16px;
  height: 16px;
  cursor: pointer;

  &:hover {
    color: #47a3f3;
  }
`;

export const Search = styled(SearchOutline)`
  ${iconStyle}
  right: 15px;
  pointer-events: none;
`;

export const Spinner = styled(Spin)`
  ${iconStyle}
  top: 12px;
  right: 15px;
  width: 16px;
  height: 16px;
  pointer-events: none;
`;

export const Details = styled.span`
  color: #616d7c;
  font-size: 14px;
  letter-spacing: 0;
  pointer-events: none;
`;

export const OptionsContainer = styled(Container)`
  position: absolute;
  z-index: 1000;
  width: 100%;
  margin-top: 4px;
  background-color: #ffffff;
  border: 1px solid #cad2d9;
  border-radius: 4px;
  box-shadow: 0 6px 16px 0 #d9e2ec;
`;

export const ItemsList = styled(List)`
  max-height: 200px;
  overflow: auto;
`;

export const SelectWrapper = styled.div<{
  open: boolean;
  showSearch: boolean;
  disabled: boolean;
  errors?: boolean;
}>`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 40px;
  margin-bottom: 4px;
  padding: 0 36px 0 16px;
  background-color: #fff;
  border-color: #cad2d9;
  border-style: solid;
  border-width: 2px;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  user-select: none;

  &:hover {
    cursor: ${({ showSearch }) => (showSearch ? 'text' : 'pointer')};
  }

  ${({ errors }) =>
    errors &&
    css`
      border-color: #f86a6a;
      background-color: #fff1f0;
    `}

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        border-color: #47a3f3;
      }
    `}

  ${Chevron} {
    ${({ open }) =>
      open &&
      css`
        color: #47a3f3;
        transition: transform 0.3s ease;
        transform: rotate(180deg);
      `}

    ${({ open }) =>
      !open &&
      css`
        transition: transform 0.3s ease;
        transform: rotate(0deg);
      `}
  }

  ${Search} {
    ${({ open }) =>
      open &&
      css`
        color: #47a3f3;
      `}
  }

  ${({ open }) =>
    open &&
    css`
      border-color: #47a3f3;
      box-shadow: 0 2px 8px -2px rgba(71, 163, 243, 0.5);
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #f5f7f9;
      border-color: #eff1f3;
      box-shadow: none;
    `}

  ${Details} {
    ${({ disabled }) =>
      disabled &&
      css`
        color: #9aa4b0;
      `}
  }

  ${Chevron} {
    ${({ disabled }) =>
      disabled &&
      css`
        color: #cad2d9;
      `}
  }
`;

export const SelectContent = styled.div`
  position: relative;
`;

export const Input = styled.input`
  width: 100%;
  color: #616d7c;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0;
  outline: none;
  caret-color: #0967d2;

  &,
  &:active,
  &:focus,
  &:hover {
    border: none;
    outline: none;
  }

  &:disabled {
    color: #9aa4b0;
    background-color: #f5f7f9;
  }

  &::placeholder {
    color: #9aa4b0;
  }
`;

export const Label = styled.label`
  margin-bottom: 4px;
  color: #9aa4b0;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1.13px;
`;

export const SelectCheckbox = styled(Checkbox)`
  pointer-events: none;

  span {
    pointer-events: none;
  }
`;
