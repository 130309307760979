import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { ReactComponent as Emblem } from 'assets/emblem.svg';
import Input from 'components/Input';
import {
  Container,
  Head,
  CompanyAvatar,
  Header,
  Form,
  SubmitButton,
} from './styles';

const emailRegex =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>().,;\s@"]+\.{0,1})+([^<>().,;:\s@"]{2,}|[\d.]+))$/;

type SignInInputs = {
  email: string;
  'current-password': string;
};

interface ISignInForm {
  onSignIn: (username: string, password: string) => void;
}

const SignInForm: React.FC<ISignInForm> = ({ onSignIn }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<SignInInputs>({ mode: 'onChange' });

  const onSubmit: SubmitHandler<SignInInputs> = async (data) => {
    onSignIn(data.email, data['current-password']);
  };

  return (
    <Container>
      <Head>
        <CompanyAvatar svg={<Emblem />} />
        <Header>Sign in to HBC Manager</Header>
      </Head>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          placeholder="Login"
          data-testid="email"
          {...register('email', {
            required: 'Email field is required.',
            pattern: {
              value: emailRegex,
              message: 'Provided email is invalid.',
            },
          })}
          errors={errors.email?.message}
        />
        <Input
          placeholder="Password"
          type="password"
          data-testid="current-password"
          {...register('current-password', {
            required: 'Password field is required.',
          })}
          autoComplete="on"
          errors={errors['current-password']?.message}
        />
        <SubmitButton
          type="submit"
          data-testid="login"
          disabled={!isDirty || !isValid}
        >
          Login
        </SubmitButton>
      </Form>
    </Container>
  );
};

export default SignInForm;
