import React, { useState } from 'react';
import {
  CardContainer,
  CardHeader,
  CardTitle,
  CardTitleExtra,
  CardBody,
  ChevronDownIcon,
} from './styles';
import { ICardProps } from './types';

const Card: React.FC<ICardProps> = ({
  children,
  title,
  extra,
  closable = false,
  defaultOpen = true,
  error = false,
  ...rest
}) => {
  const [open, setOpen] = useState(!closable || defaultOpen);

  const handleClose = () => {
    if (closable) {
      setOpen(!open);
    }
  };

  return (
    <CardContainer {...rest}>
      <CardHeader open={open} error={!closable ? false : error}>
        <CardTitle closable={closable} onClick={() => handleClose()}>
          {closable ? <ChevronDownIcon open={open} /> : <></>}
          {title}
        </CardTitle>
        <CardTitleExtra>{extra}</CardTitleExtra>
      </CardHeader>
      <CardBody open={open} closable={closable}>
        {children}
      </CardBody>
    </CardContainer>
  );
};

export default Card;
