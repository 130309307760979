import React, { forwardRef } from 'react';

import {
  InputContainer,
  Label,
  InputField,
  ErrorsContainer,
  ErrorIcon,
  Errors,
  InputElement,
  AddonBefore,
  AddonAfter,
} from './styles';
import { renderErrors } from './utils';
import { IInputField } from './types';

const Input = forwardRef<HTMLInputElement, IInputField>(function Input(
  { label, id, errors, addonAfter, addonBefore, ...rest },
  ref
) {
  return (
    <InputContainer>
      {label && <Label htmlFor={id}>{label}</Label>}
      <InputElement>
        {addonBefore && (
          <AddonBefore errors={!!errors?.length}>{addonBefore}</AddonBefore>
        )}
        <InputField
          spellCheck="false"
          ref={ref}
          id={id}
          errors={!!errors?.length}
          addonBefore={!!addonBefore}
          addonAfter={!!addonAfter}
          {...rest}
        />
        {addonAfter && (
          <AddonAfter errors={!!errors?.length}>{addonAfter}</AddonAfter>
        )}
      </InputElement>
      {errors?.length && (
        <ErrorsContainer>
          <ErrorIcon />
          <Errors>{renderErrors(errors)}</Errors>
        </ErrorsContainer>
      )}
    </InputContainer>
  );
});

export default Input;
