import styled, { css } from 'styled-components';
import { DropdownPosition } from './types';

export const itemIcon = css`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`;

export const Item = styled.li<{ active?: boolean }>`
  display: flex;
  align-items: center;
  height: 40px;
  margin: 0 8px;
  padding: 0 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  svg:not(.checkmark) {
    fill: #9aa4b0;
  }

  &:hover {
    background-color: #f5f7f9;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: #f5f7f9;
    `}
`;

export const DropdownContainer = styled.div`
  position: relative;
`;

export const ItemsContainer = styled.div<{ position: DropdownPosition }>`
  position: absolute;
  z-index: 1000;
  width: max-content;
  min-width: 108px;
  margin-top: 3px;
  overflow: hidden;
  color: #616d7c;
  font-size: 14px;
  background-color: #ffffff;
  border: 1px solid #cad2d9;
  border-radius: 4px;
  box-shadow: 0 6px 16px 0 #d9e2ec;

  ${({ position }) =>
    position === 'left' &&
    css`
      left: 0;
      right: auto;
    `}

  ${({ position }) =>
    position === 'right' &&
    css`
      right: 0;
      left: auto;
    `}

  ${({ position }) =>
    position === 'bottom' &&
    css`
      top: 100%;
      left: 0;
    `}
`;

export const ItemsList = styled.ul`
  margin: 0;
  padding: 0;
  color: #616d7c;
  font-size: 14px;

  li:first-child {
    margin-top: 8px;
  }

  li:last-child {
    margin-bottom: 8px;
  }
`;
