const apiUrl = process.env.REACT_APP_API_URL;

const urls = {
  healthCheck: `${apiUrl}/health`,
};

const routes = {
  signIn: '/sign-in',
  setPassword: '/set-password',
  publishers: '/publishers',
  publisherForm: '/publisher/:publisherId?',
  containers: '/containers',
  containerForm: '/container/:containerId?',
  setupInstructions: '/container/:containerId/setup',
} as { [key: string]: string };

const awsConfig = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
  },
};

export { apiUrl, urls, routes, awsConfig };
