import styled from 'styled-components';

export const Header = styled.div<{ spaced?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ spaced }) => (spaced ? '30px' : '14px')};
`;

export const Footer = styled(Header)``;

export const Container = styled.div`
  width: 100%;
`;
