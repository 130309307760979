import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  position: relative;
  top: 20%;
  max-width: 560px;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  border: 1px solid rgba(228, 232, 240, 0.6);
  border-radius: 4px;
  box-shadow: 0 10px 20px -8px rgba(16, 42, 67, 0.5);
`;

export const Mask = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  width: 100vw;
  height: 100vh;
  background-color: rgba(62, 75, 88, 0.6);
`;

export const Content = styled.div`
  padding: 48px;
  background-color: #ffffff;
`;

export const Title = styled.span`
  color: #1e2833;
  font-weight: 500;
  font-size: 16px;
`;

export const Description = styled.p`
  margin-top: 22px;
  color: #9aa4b0;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 22px 48px;
  background-color: #f5f7f9;
`;
