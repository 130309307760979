import styled, { css } from 'styled-components';
import { ChevronDownOutline, ChevronUpOutline } from '@easy-eva-icons/react';

const iconStyle = css`
  width: 22px;
  height: 22px;
  margin-right: 16px;
`;

export const ChevronDownIcon = styled(ChevronDownOutline)<{ open: boolean }>`
  ${iconStyle}

  ${({ open }) =>
    open &&
    css`
      transition: transform 0.3s ease;
      transform: rotate(180deg);
    `}

  ${({ open }) =>
    !open &&
    css`
      transition: transform 0.3s ease;
      transform: rotate(0deg);
    `}
`;

export const ChevronUpIcon = styled(ChevronUpOutline)`
  ${iconStyle}
`;

export const CardContainer = styled.div`
  background-color: #fff;
  border: 1px solid rgba(202, 210, 217, 0.3);
  border-radius: 4px;
  box-shadow: 0 6px 16px 0 rgba(217, 226, 236, 0.25);
`;

export const CardHeader = styled.div<{ open: boolean; error: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 19px 24px;
  color: #9aa4b0;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0;

  ${({ open }) =>
    open &&
    css`
      border-bottom: 1px solid rgba(202, 210, 217, 0.3);
    `}

  ${({ error }) =>
    error &&
    css`
      border: 2px solid #f86a6a;
      background-color: #fff1f0;
    `}
`;

export const CardTitle = styled.span<{ closable: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ closable }) =>
    closable &&
    css`
      user-select: none;
      cursor: pointer;
    `}
`;

export const CardTitleExtra = styled.span``;

export const CardBody = styled.div<{ open: boolean; closable: boolean }>`
  padding: 24px;

  ${({ closable }) =>
    closable &&
    css`
      display: none;
    `}

  ${({ open }) =>
    open &&
    css`
      display: block;
    `}
`;
