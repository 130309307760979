import React from 'react';
import { AvatarContainer, AvatarImage } from './styles';
import { IAvatar } from './types';

const Avatar: React.FC<IAvatar> = ({ src, svg, ...rest }) => (
  <AvatarContainer {...rest}>
    {src ? <AvatarImage src={src} alt="" /> : svg}
  </AvatarContainer>
);

export default Avatar;
